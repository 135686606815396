import RequestHandler from '../../../customize/requestHandler';

export const EditUserProfileInformation = async data => {
	return RequestHandler.call({ url: '/api/v1/users/profile-info', method: 'put', data }).then(res => res.data.result);
};

export const GetUserProfileInformation = async () => {
	return RequestHandler.call({ url: '/api/v1/users/profile-info', method: 'get' }).then(res => res.data.result);
};

export const UploadNewAvatar = async data => {
	return RequestHandler.call({ url: '/api/v1/users/profile-info/avatar', method: 'post', data }).then(res => res.data.result);
};

export const GetUserChartInformation = async (username = '', startDate = '', endDate = '') => {
	return RequestHandler.call({
		url: `/api/v1/futures/profile/${username}/charts?startDate=${startDate ?? ''}&endDate=${endDate ?? ''}`,
		method: 'get',
	}).then(res => res.data.result);
};

export const GetUserPublicProfile = async username => {
	return RequestHandler.call({ url: `/api/v1/users/public-profile/${username}`, method: 'get' }).then(res => res.data.result);
};

export const DeleteSpecificAvatar = async () => {
	return RequestHandler.call({ url: `/api/v1/users/profile-info/avatar`, method: 'delete' }).then(res => res.data.result);
};
