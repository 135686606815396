import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalMainContainer = styled.div(
	({ theme }) => css`
		padding: 20px;

		* {
			font-family: 'Product Sans' !important;
		}

		.modal_body {
			height: 500px;
			overflow: auto;
			padding-right: 10px;
			margin-right: -10px;
		}

		h3 {
			font-size: ${theme.size.text.ts20};
			color: ${theme.colors.text.primary};
			font-weight: 700;
			margin-bottom: 16px;
			padding-bottom: 8px;
			border-bottom: 1px solid ${theme.colors.border.primary};
		}

		h4 {
			font-size: ${theme.size.text.ts14};
			color: ${theme.colors.text.primary};
			margin-bottom: 8px;
		}

		p {
			font-size: ${theme.size.text.ts12};
			color: ${theme.colors.text.secondary};
			margin-bottom: 12px;
			font-weight: 300;

			b {
				color: ${theme.colors.text.primary};
			}
		}

		ol {
			font-size: ${theme.size.text.ts12};
			color: ${theme.colors.text.secondary};
			font-weight: 300;
			margin-bottom: 24px;
			display: block;
		}

		.lang_field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 100%;

			.card_row {
				display: flex;
				align-items: center;
				gap: 8px;
				border: 1px solid ${theme.colors.border.primary};
				border-radius: 4px;
				width: 100%;
				padding: 8px 12px;
				height: 72px;
				text-align: center;
				justify-content: center;
				cursor: pointer;

				p {
					font-size: ${theme.size.text.ts12};
					color: ${theme.colors.text.primary};
					margin: 0;
					margin-top: 2px;
				}
			}

			svg {
				min-width: 32px;
				height: 32px;
			}
		}

		button {
			margin-top: 32px;
			width: 100%;
		}

		.active {
			background-color: ${theme.colors.background.secondary};
		}

		@media (min-width: 428px) {
			padding: 24px;

			h3 {
				margin-bottom: 16px;
				padding-bottom: 12px;
			}

			h4 {
				font-size: ${theme.size.text.ts16};
			}

			p {
				font-size: ${theme.size.text.ts14};
			}

			.lang_field {
				.card_row {
					height: 64px;
					gap: 12px;
					padding: 8px 16px;

					p {
						font-size: ${theme.size.text.ts14};
						color: ${theme.colors.text.primary};
						margin: 0;
						margin-top: 2px;
					}
				}

				svg {
					min-width: 32px;
					height: 32px;
				}
			}
		}

		@media (max-height: 700px) {
			.modal_body {
				height: 300px;
			}
		}
	`,
);
