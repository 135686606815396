import React, { useState, useEffect } from 'react';

// assets
import {
	Input,
	Label,
	InputGroup,
	InlineField,
	StyledButton,
	StyledListbox,
	StyledOption,
	StyledPopper,
	Textarea,
	InputButton,
	SearchInputField,
	Small,
	SelectField,
	CustomSelectField,
	InputTitle,
} from '../assets/css/customize/input';

// api request
import { GetCountries } from '../api-requests/layout/country';

// mui
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import SelectUnstyled from '@mui/base/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

export function SimpleInput(props) {
	const {
		label,
		type,
		name,
		getVal,
		value,
		havebutton,
		buttonFUnc,
		small,
		borderLess,
		placeholder,
		maxLength,
		seconds,
		title,
		disabled,
	} = props;
	const [seePass, setSeePass] = useState(true);
	const handleSeePass = () => {
		setSeePass(!seePass);
	};
	return (
		<InputGroup>
			{label && <Label>{label}</Label>}
			{title && <InputTitle type={title}>{title}</InputTitle>}
			<Input
				type={seePass ? type : 'text'}
				name={name}
				onChange={getVal}
				value={value}
				autoComplete='off'
				placeholder={placeholder}
				className={borderLess ? 'border_less' : ''}
				maxLength={maxLength && maxLength}
				disabled={disabled}
			/>
			{small && <Small>{small}</Small>}
			{type === 'password' && (
				<IconButton className='showPassword' onClick={handleSeePass}>
					{seePass ? <i className='fas fa-eye-slash'></i> : <i className='fas fa-eye'></i>}
				</IconButton>
			)}
			{havebutton && (
				<InputButton onClick={buttonFUnc} disabled={seconds === 0 ? false : 'disabled'}>
					{seconds === 0 ? 'Resend Code' : `Resend Code (${seconds}s) `}
				</InputButton>
			)}
		</InputGroup>
	);
}
export function TextareaInput(props) {
	const { label, type, name, getVal, value } = props;
	const [seePass] = useState(true);
	return (
		<InputGroup>
			<Label>{label}</Label>
			<Textarea type={seePass ? type : 'text'} name={name} onChange={getVal} value={value} autoComplete='off'></Textarea>
		</InputGroup>
	);
}

export function MobileInput(props) {
	const [countrie, setcontrie] = useState([{}]);
	useEffect(() => {
		GetCountries().then(res => {
			setcontrie(res);
		});
	}, []);
	const { label, type, name, getVal, getCode, value } = props;

	return (
		<InputGroup>
			<Label>{label}</Label>
			<InlineField>
				<Autocomplete
					id='country-select-demo'
					sx={{ width: 200, height: 26 }}
					options={countrie}
					autoHighlight
					disablePortal={true}
					onChange={(event, newValue) => {
						getCode(newValue.dialCode);
					}}
					defaultValue={{
						code: 'AF',
						dialCode: '+93',
						id: 247,
						logoUrl: 'https://api.coinlocally.com:443/images/countries/AF.png',
						name: 'Afghanistan',
					}}
					getOptionLabel={option => `${option.dialCode} - ${option.name}`}
					renderOption={(props, option) => (
						<Box component='li' sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
							<img loading='lazy' width='20' src={option.logoUrl} alt='' />
							<span style={{ fontSize: '0.8rem' }}>
								{option.name} {option.dialCode}
							</span>
						</Box>
					)}
					renderInput={params => (
						<TextField
							{...params}
							label='Choose a country'
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password',
							}}
						/>
					)}
				/>
				<Input type={type} name={name} onChange={getVal} value={value} autoComplete='off' />
			</InlineField>
		</InputGroup>
	);
}

export function SearchInput(props) {
	const { name, placeholder, value, getVal, type } = props;

	return (
		<SearchInputField>
			<i className='fal fa-search'></i>
			<Input placeholder={placeholder} type={type} name={name} value={value} onChange={getVal} autoComplete='off' />
		</SearchInputField>
	);
}

export function SelectInput(props) {
	const { placeholder, name, label, option, selectOption, getInputValue, inputVal, selectValue, type } = props;
	return (
		<SelectField>
			<Label>{label}</Label>
			<Input type={type} placeholder={placeholder} name={name} autoComplete='off' onChange={getInputValue} value={inputVal} />
			<Autocomplete
				options={option}
				autoHighlight
				value={selectValue}
				isOptionEqualToValue={(option, value) => option === value}
				getOptionLabel={option => option}
				renderInput={params => <TextField {...params} />}
				onChange={(event, newValue) => {
					if (newValue === null) {
						selectOption('');
					} else {
						selectOption(newValue);
					}
				}}
			/>
		</SelectField>
	);
}

export const CountrySelect = props => {
	const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
		const components = {
			Root: StyledButton,
			Listbox: StyledListbox,
			Popper: StyledPopper,
			...props.components,
		};
		return <SelectUnstyled {...props} ref={ref} components={components} />;
	});

	CustomSelect.propTypes = {
		components: PropTypes.shape({
			Listbox: PropTypes.elementType,
			Popper: PropTypes.elementType,
			Root: PropTypes.elementType,
		}),
	};

	const [countrie, setcontrie] = useState([{}]);
	const { label, getCode, value } = props;

	useEffect(() => {
		GetCountries().then(res => {
			setcontrie(res);
		});
	}, []);

	return (
		<InputGroup>
			<Label>{label}</Label>
			<CustomSelectField>
				<CustomSelect value={value} onChange={getCode}>
					{countrie.length
						? countrie?.map((c, index) => (
								<StyledOption key={index} value={c.id}>
									<div>
										<img loading='lazy' width='20' src={c.logoUrl} alt={`Flag of ${c.label}`} />
										{c.name}
									</div>
								</StyledOption>
						  ))
						: null}
				</CustomSelect>
			</CustomSelectField>
		</InputGroup>
	);
};

export const NormalSelect = props => {
	const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
		const components = {
			Root: StyledButton,
			Listbox: StyledListbox,
			Popper: StyledPopper,
			...props.components,
		};
		return <SelectUnstyled {...props} ref={ref} components={components} />;
	});

	CustomSelect.propTypes = {
		components: PropTypes.shape({
			Listbox: PropTypes.elementType,
			Popper: PropTypes.elementType,
			Root: PropTypes.elementType,
		}),
	};
	const { label, value, options, getVal } = props;

	return (
		<InputGroup>
			<Label>{label}</Label>
			<CustomSelectField>
				<CustomSelect value={value} onChange={getVal}>
					{options
						? options.map((c, index) => (
								<StyledOption key={index} value={c}>
									<div>{c}</div>
								</StyledOption>
						  ))
						: null}
				</CustomSelect>
			</CustomSelectField>
		</InputGroup>
	);
};
