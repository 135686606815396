import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

//component
import ButtonLoader from '../../../customize/loader/button-loader';

//assets
import { MainField, HeaderFiled, ActionField, SocialField, ModalBody } from '../../../assets/css/profile/profile/edit';
import Twitter from '../../../assets/img/twitter.svg';
import Instagram from '../../../assets/img/instagram.svg';
import Telegram from '../../../assets/img/telegram.svg';
import Website from '../../../assets/img/website.svg';
import AP00 from '../../../assets/img/AP00.svg';

//api request
import {
	EditUserProfileInformation,
	GetUserProfileInformation,
	UploadNewAvatar,
	DeleteSpecificAvatar,
} from '../../../api-requests/dashboard/profile/user-profile';

//mui
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function EditProfileBody() {
	var formData = new FormData();
	const basicKyc = useSelector(state => state.Profile.basicKyc.status);
	const advanceKyc = useSelector(state => state.Profile.advanceKyc.status);
	const [btnLoader, setBtnLoader] = useState(false);
	const [apiResults, setApiResults] = useState();
	const [fileUploadStatus, setFileUploadStatus] = useState(false);
	const [image, setImage] = useState();
	const [imageName, setImageName] = useState();
	const [cropper, setCropper] = useState();
	const [ModalStatus, setModalStatus] = useState(false);

	const { t } = useTranslation('profile');

	const handleCLoseModal = () => {
		setModalStatus(false);
	};

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

	const getCropData = () => {
		if (typeof cropper !== 'undefined') {
			formData.delete('avatar');
			formData.append('avatar', dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), Date.now() + '.png'));
			const toastId = toast.loading('Loading...');
			UploadNewAvatar(formData)
				.then(() => {
					setFileUploadStatus(!fileUploadStatus);
					handleCLoseModal();
				})
				.finally(() => {
					toast.dismiss(toastId);
				});
		}
	};

	const [inputValues, setInputsValues] = useState({
		userName: '',
		isProfilePrivate: false,
		bio: '',
		instagramUrl: '',
		twitterUrl: '',
		telegramUrl: '',
		websiteUrl: '',
		birthDate: null,
	});

	useEffect(() => {
		GetUserProfileInformation().then(res => {
			setInputsValues({
				userName: res.userName ?? '',
				isProfilePrivate: res.isProfilePrivate,
				bio: res.bio ?? '',
				instagramUrl: res.instagramUrl ?? '',
				twitterUrl: res.twitterUrl ?? '',
				telegramUrl: res.telegramUrl ?? '',
				websiteUrl: res.websiteUrl ?? '',
				birthDate: res.birthDate ?? null,
			});
			setApiResults({
				avatar: res.avatar,
				name: res.userName,
			});
		});
	}, [fileUploadStatus]);

	const getValuehandler = (e, type) => {
		if (type === 'input') {
			setInputsValues({
				...inputValues,
				[e.target.name]: e.target.value,
			});
		} else {
			setInputsValues({
				...inputValues,
				isProfilePrivate: e.target.checked,
			});
		}
	};

	const ChoseFileHandler = e => {
		if (parseInt(e.target.files[0].size / 1024 / 1024) > 1) {
			toast.error('Your file size should not exceed 1 MB');
		} else {
			let files;
			if (e.dataTransfer) {
				files = e.dataTransfer.files;
			} else if (e.target) {
				files = e.target.files;
			}
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result);
			};
			reader.readAsDataURL(files[0]);
			setImageName(files[0].name);
			setModalStatus(true);
		}
	};

	const userKycType = () => {
		if (advanceKyc === 'CONFIRMED') {
			return 'Verified +';
		}
		if ((!advanceKyc && basicKyc === 'CONFIRMED') || advanceKyc === 'PENDING') {
			return 'Verified';
		}
		if ((!advanceKyc && !basicKyc) || basicKyc === 'PENDING') {
			return 'Not Verified';
		}
	};

	const editProfileInformationHandler = () => {
		// eslint-disable-next-line
		const UrlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
		if (inputValues.userName === '') {
			toast.error('Please fill your username !');
		} else if (inputValues.instagramUrl !== '' && !UrlRegex.test(inputValues.instagramUrl)) {
			toast.error('Your instagram URL is inavlid !');
		} else if (inputValues.twitterUrl !== '' && !UrlRegex.test(inputValues.twitterUrl)) {
			toast.error('Your twitter URL is inavlid !');
		} else if (inputValues.telegramUrl !== '' && !UrlRegex.test(inputValues.telegramUrl)) {
			toast.error('Your telegram URL is inavlid !');
		} else if (inputValues.userName.length < 3 && inputValues.userName.length > 20) {
			toast.error('username length must greater than 3 and smaller than 20 !');
		} else {
			setBtnLoader(true);
			EditUserProfileInformation({ ...inputValues }).finally(() => {
				setBtnLoader(false);
			});
		}
	};

	const removeAvatarhandler = () => {
		DeleteSpecificAvatar().then(() => {
			setFileUploadStatus(!fileUploadStatus);
		});
	};

	return (
		<MainField>
			<HeaderFiled avatar={apiResults?.avatar ?? AP00} avatarSize={apiResults?.avatar ? true : false}>
				<div className='avatar_field'>
					{apiResults?.avatar ? (
						<i className='fal fa-times' onClick={removeAvatarhandler}></i>
					) : (
						<label htmlFor='file_uploader'>
							<i className='fal fa-pen'></i>
						</label>
					)}
					<input type='file' name='attachmentUrl' id='file_uploader' accept='image/*' onChange={ChoseFileHandler} hidden />
				</div>
				<div className='information'>
					<div className='name'>
						<b>{apiResults?.name ?? '---'}</b>
						<span></span>
						{userKycType()}
					</div>
					<small>{t('update_nickname')}</small>
				</div>
			</HeaderFiled>
			<ActionField>
				<div className='form_group'>
					<label>{t('nickname')}</label>
					<input type='text' name='userName' value={inputValues.userName} onChange={e => getValuehandler(e, 'input')} />
				</div>
				<div className='form_group'>
					<label>{t('birth_date')}</label>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							value={inputValues.birthDate ?? null}
							maxDate={new Date()}
							onChange={newValue => {
								if (newValue) {
									let date = new Date(new Date(newValue).toLocaleDateString());
									let birthDate = date.getTime();
									setInputsValues({
										...inputValues,
										birthDate,
									});
								}
							}}
							renderInput={params => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
				<div className='form_group w_100'>
					<label>{t('bio')}</label>
					<textarea name='bio' value={inputValues.bio} onChange={e => getValuehandler(e, 'input')} />
				</div>
				<FormControlLabel
					control={<Checkbox checked={inputValues.isProfilePrivate} onChange={e => getValuehandler(e, 'checkbox')} />}
					label={t('invisible_pnl_roi')}
				/>
			</ActionField>
			<SocialField>
				<h3>{t('social_profile')}</h3>

				<div className='form_field'>
					<div className='form_group'>
						<label>Twitter</label>
						<input
							type='text'
							name='twitterUrl'
							value={inputValues.twitterUrl}
							onChange={e => getValuehandler(e, 'input')}
							placeholder='twitter.com/username'
						/>
						<img src={Twitter} alt='' />
					</div>
					<div className='form_group'>
						<label>Telegram</label>
						<input
							type='text'
							name='telegramUrl'
							value={inputValues.telegramUrl}
							onChange={e => getValuehandler(e, 'input')}
							placeholder='t.me/username'
						/>
						<img src={Telegram} alt='' />
					</div>
					<div className='form_group'>
						<label>Instagram</label>
						<input
							type='text'
							name='instagramUrl'
							value={inputValues.instagramUrl}
							onChange={e => getValuehandler(e, 'input')}
							placeholder='instagram.com/username'
						/>
						<img src={Instagram} alt='' />
					</div>
					<div className='form_group'>
						<label>Website</label>
						<input
							type='text'
							name='websiteUrl'
							value={inputValues.websiteUrl}
							onChange={e => getValuehandler(e, 'input')}
							placeholder='www.example.com'
						/>
						<img src={Website} alt='' />
					</div>
				</div>
			</SocialField>
			<Button variant='contained' className='save_btn' onClick={editProfileInformationHandler}>
				<ButtonLoader loading={btnLoader} title={t('save_changes')} />
			</Button>
			<Dialog
				TransitionComponent={Transition}
				open={ModalStatus ?? false}
				scroll='body'
				onClose={handleCLoseModal}
				fullWidth={true}
				maxWidth='sm'
			>
				<ModalBody>
					<Cropper
						aspectRatio={1 / 1}
						preview='.img-preview'
						src={image}
						viewMode={1}
						background={false}
						responsive={true}
						checkOrientation={false}
						onInitialized={instance => {
							setCropper(instance);
						}}
						guides={true}
					/>
					<div className='button_group'>
						<Button variant='contained' onClick={handleCLoseModal}>
							{t('close')}
						</Button>
						<Button variant='contained' onClick={getCropData}>
							{t('crop_image')}
						</Button>
					</div>
				</ModalBody>
			</Dialog>
		</MainField>
	);
}

export default EditProfileBody;
