import { createSlice } from '@reduxjs/toolkit';

export const BuyCrypto = createSlice({
	name: 'buy-crypto-info',
	initialState: {
		channel: '',
		coin: '',
		channelStatus: false,
		coinStatus: false,
		termsStatus: false,
	},
	reducers: {
		getChannel: (state, action) => {
			state.channel = action.payload;
		},
		changeCoin: (state, action) => {
			state.coin = action.payload;
		},
		changeChannelStatus: (state, action) => {
			state.channelStatus = action.payload;
		},
		changeCoinStatus: (state, action) => {
			state.coinStatus = action.payload;
		},
		changeTermsStatus: (state, action) => {
			state.termsStatus = action.payload;
		},
	},
});

export const { getChannel, changeCoin, changeChannelStatus, changeCoinStatus, changeTermsStatus } = BuyCrypto.actions;
export default BuyCrypto.reducer;
