import { useEffect, Suspense, lazy, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useSearchParams, useParams, Outlet } from 'react-router-dom';
import { IsLogin } from '../../state-manager/reducer/profile';
import ReactGA from 'react-ga';

// api request
import { getUserInfo, getSecurityStrategiesApi } from '../../api-requests/dashboard/profile/user-info';

// mui
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getDesignTokens } from 'config/theme';

// components
import Loader from '../../component/layout/loader';
import EditProfileBody from '../profile/profile/edit';
import RoutesJson, { RoutesLayoutHandler } from '../../config/routes-items';
import 'cly-uikit/css/global.min.css';
import 'cly-uikit/css/font/oswald.min.css';
import 'cly-uikit/css/font/product-sans.min.css';
import '@coinlocally/cly-core/css/colors.css';
import AppModal from '../../shared/components/app-modal';
import { useTranslation } from 'react-i18next';
import { GetServiceSession } from '../../api-requests/auth/api-call';
import { Toaster } from 'react-hot-toast';
import { changeIsMobileStatus } from 'state-manager/reducer/home';
import { ClyCoreConfig } from '@coinlocally/cly-core';
import ProfileLangModal from 'shared/components/modal/email-lang-modal/profile-lang-modal';
import BlackFriday from 'shared/components/modal/black-friday';
import { useAppSelector } from 'hooks/redux-helper';
import { RouteTheme } from 'config/route-theme';
import { flush, injectGlobal } from '@emotion/css';

const Dashboard = lazy(() => import('../../route/profile/index'));
const Referral = lazy(() => import('../../route/profile/referral'));
const Security = lazy(() => import('../../route/profile/security'));
const PartnerInformation = lazy(() => import('../../route/profile/partner/information'));
const PartnerCredentials = lazy(() => import('../../route/profile/partner/credentials'));
const PartnerClients = lazy(() => import('../../route/profile/partner/clients'));
const PartnerDeposit = lazy(() => import('../../route/profile/partner/deposit'));
const PartnerTransfer = lazy(() => import('../../route/profile/partner/transfers'));
const SupportTicket = lazy(() => import('../../route/profile/support-ticket'));
const ActivityLog = lazy(() => import('../../route/profile/activity-log'));
const Notificaiton = lazy(() => import('../../route/profile/notification'));
const DashbordLayout = lazy(() => import('../layout/dashboard-layout'));
const FAQ = lazy(() => import('../../route/other/faq/faq'));
const FAQEarn = lazy(() => import('../../route/other/faq/earn'));
const FAQCrossMargin = lazy(() => import('../../route/other/faq/cross-margin'));
const GettingStarted = lazy(() => import('../../route/other/faq/getting-started'));
const DepositWithdraw = lazy(() => import('../../route/other/faq/deposit-withdraw'));
const NotFound = lazy(() => import('../../route/not-found/404'));
const FAQSecurity = lazy(() => import('../../route/other/faq/security'));
const HotIssue = lazy(() => import('../../route/other/faq/hot-issue'));
const FuturesFaq = lazy(() => import('../../route/other/faq/futures'));
const AppSpot = lazy(() => import('../../route/other/faq/app-spot'));
const KYC = lazy(() => import('../../route/profile/KYC/index'));
const KYCCorporation = lazy(() => import('../../route/profile/KYC/corporation-KYC'));
const KYCBasicInformation = lazy(() => import('../../route/profile/KYC/basic-information'));
const KYCUploadDocument = lazy(() => import('../../route/profile/KYC/upload-document'));
const VIP = lazy(() => import('../../route/profile/VIP.jsx'));
const Balances = lazy(() => import('../../route/profile/balances'));
const SmallBalances = lazy(() => import('../../route/profile/small-balances'));
const DepositHistory = lazy(() => import('../../route/profile/history/deposit'));
const WithdrawsHistory = lazy(() => import('../../route/profile/history/withdraws'));
const FiatHistory = lazy(() => import('../../route/profile/history/fiat'));
const TransferHistory = lazy(() => import('../../route/profile/history/transfer'));
const EarnHistory = lazy(() => import('../../route/profile/history/earn'));
const SpotHistory = lazy(() => import('../../route/profile/history/spot'));
const FuturesHistory = lazy(() => import('../../route/profile/history/futures'));
const NFTHome = lazy(() => import('../../route/nft/home'));
const NFTDashboardLayout = lazy(() => import('../layout/nft/dashboard-layout'));
const NFTWallet = lazy(() => import('../../route/nft/profile/wallet'));
const NFTDeposit = lazy(() => import('../../route/nft/profile/deposit'));
const NFTDepositHistory = lazy(() => import('../../route/nft/profile/history/deposit'));
const NFTMarketplace = lazy(() => import('../../route/nft/marketplace'));
const NFTProduct = lazy(() => import('../../route/nft/nft-product'));
const NFTFavorit = lazy(() => import('../../route/nft/profile/favorit'));
const NFTWidthrawHistory = lazy(() => import('../../route/nft/profile/history/widthraw'));
const NFTBiddingHistory = lazy(() => import('../../route/nft/profile/history/bidding'));
const NFTPurchaseHistory = lazy(() => import('../../route/nft/profile/history/purchase'));
const NFTSalesHistory = lazy(() => import('../../route/nft/profile/history/sales'));
const NFTCollected = lazy(() => import('../../route/nft/profile/collected'));
const NFTUSersProfile = lazy(() => import('../../route/nft/profile/users-profile'));
const NFTCollectionDetail = lazy(() => import('../../route/nft/collection-detail'));
const NFTWithdraw = lazy(() => import('../../route/nft/profile/withdraw'));
const Quests = lazy(() => import('../../route/profile/quests'));
const EditProfile = lazy(() => import('../../route/profile/profile/edit'));
const FeeRates = lazy(() => import('../../route/profile/fee-rates'));
const FuturesOverView = lazy(() => import('../../pages/dashboard/overview/futures'));
const SavingHistory = lazy(() => import('../../pages/saving-history'));
const Referees = lazy(() => import('../../route/profile/referees'));

const version = require('../../../package.json').version;

function RequireAuth({ children }) {
	const isLogin = useSelector(state => state.Profile.isLogin);
	const location = useLocation();
	const dispatch = useDispatch();
	if (localStorage.getItem('refresh_auth_token') === null) {
		dispatch(IsLogin(false));
	}

	const language = useTranslation().i18n.language;

	return isLogin ? children : <Navigate to={`/${language}/login?callbackUrl=${location.pathname}`} replace />;
}

function RequireNoAuth({ children }) {
	const isLogin = useSelector(state => state.Profile.isLogin);
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const { i18n } = useTranslation();
	let language = i18n.language;

	if (searchParams.get('returnUrl') && searchParams.get('returnUrl') === 'https://bot.coinlocally.com') {
		if (isLogin) {
			GetServiceSession().then(res => {
				window.location.href = searchParams.get('returnUrl') + '?token=' + res.token;
			});
		} else {
			return children;
		}
	} else {
		if (searchParams.get('page')) {
			if (!isLogin) {
				return children;
			} else {
				if (searchParams.get('page') === 'buycrypto') {
					return (
						<Navigate
							to={`/${language}/dashboard/profile?page=${searchParams.get('page')}`}
							replace
							state={{ path: location.pathname }}
						/>
					);
				} else {
					return <Navigate to={`/${language}/${searchParams.get('page')}`} replace state={{ path: location.pathname }} />;
				}
			}
		} else {
			return !isLogin ? children : <Navigate to={`/${language}/dashboard/profile`} replace state={{ path: location.pathname }} />;
		}
	}
}

function ApplyQueryStrings() {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	useEffect(() => {
		if (searchParams.get('isMobile') && searchParams.get('isMobile') === 'true') {
			dispatch(changeIsMobileStatus(true));
		} else {
			dispatch(changeIsMobileStatus(false));
		}
		// eslint-disable-next-line
	}, [searchParams]);

	return <Outlet />;
}

function LanguageHandler() {
	const { lng } = useParams();
	const { i18n } = useTranslation();
	const location = useLocation();
	let language = i18n.language;
	let languages = Object.keys(i18n.options.resources);

	if (i18n.language.includes('-')) {
		language = i18n.language.split('-')[0];
	}

	i18n.changeLanguage(languages);

	useEffect(() => {
		if (!lng || lng.length !== 2) {
			window.location.replace(language + location.pathname);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (lng.length === 2 && lng !== i18n.language) {
			if (languages.includes(lng)) {
				i18n.changeLanguage(lng);
			} else {
				window.location.replace(location.pathname.replace(lng, language));
			}
		}
	}, [lng]);

	return <Outlet />;
}

function ThemeHandler({ children }) {
	const location = useLocation();
	const them = useSelector(state => state.ThemeProvider.theme);
	const [themeToken, setThemeToken] = useState();

	useEffect(() => {
		const findRouteTheme = RouteTheme.find(item => location.pathname.includes(item.route));
		if (findRouteTheme) {
			setThemeToken(createTheme(getDesignTokens(findRouteTheme.theme, 'ltr')));
			document.querySelector('body').setAttribute('data-theme', findRouteTheme.theme);
		} else {
			setThemeToken(createTheme(getDesignTokens(them, 'ltr')));
			document.querySelector('body').setAttribute('data-theme', them);
		}

		injectGlobal`
			input {
				&:-webkit-autofill,:-webkit-autofill:hover,:-webkit-autofill:focus {
					-webkit-text-fill-color: ${them === 'dark' ? 'white' : 'black'};
					box-shadow: 0 0 0px 40rem ${them === 'dark' ? '#353e47' : '#F5F5FF'} inset;
					caret-color: ${them === 'dark' ? 'white' : 'black'};
					border: 10px solid ${them === 'dark' ? '#353e47' : '#F5F5FF'} !important;
					padding: 0 10px !important;
				}
			}

			#navbar_theme{
				pointer-events: ${findRouteTheme ? 'none' : 'initial'};
				opacity: ${findRouteTheme ? '0.5' : '1'};
			}
        `;
		return () => flush();
	}, [them, location.pathname]);

	if (themeToken) {
		return <ThemeProvider theme={themeToken}>{children}</ThemeProvider>;
	}
}

function App() {
	ReactGA.initialize('UA-169230006-1');

	const isLogin = useSelector(state => state.Profile.isLogin);
	const userSelectedLanguage = useAppSelector(state => state.Profile.language);
	const dispatch = useDispatch();
	const RoutesJSON = RoutesJson();

	useEffect(() => {
		if (isLogin) {
			getUserInfo(dispatch);
			getSecurityStrategiesApi(dispatch);
		}
	}, [isLogin]);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	function ScrollToTop() {
		const location = useLocation();

		useEffect(() => {
			window.scrollTo(0, 0);
		}, [location.pathname]);

		return null;
	}

	const Redirect = ({ path }) => {
		const language = useTranslation().i18n.language;
		const { pair } = useParams();
		useEffect(() => {
			window.location.replace(`/${language}${path}${pair ? `/${pair}` : ''}`);
			// eslint-disable-next-line
		}, []);

		return null;
	};
	ClyCoreConfig.APP_VERSION = version;
	ClyCoreConfig.LANGUAGE = useTranslation().i18n.language;

	return (
		<BrowserRouter>
			<ThemeHandler>
				<Suspense fallback={<Loader />}>
					<ScrollToTop />
					<ApplyQueryStrings />
					<Toaster
						position='bottom-right'
						containerStyle={{
							zIndex: 9999999999,
						}}
					/>
					<Routes>
						<Route path='' element={<LanguageHandler />} />
						<Route path=':lng/*' element={<NotFound />} />
						<Route path=':lng/404' element={<NotFound />} />
						<Route path=':lng/trade' element={<Redirect path={'/spot'} />} />
						<Route path=':lng/trade/:pair' element={<Redirect path={'/spot'} />} />
						<Route path=':lng/api-key' element={<Redirect path={'/dashboard/api-key'} />} />

						{RoutesJSON.map((item, index) => {
							if (item.authType === 'guardless') {
								return (
									<Route
										path={item.route}
										element={
											<RoutesLayoutHandler layout={item.layout}>
												<item.component />
											</RoutesLayoutHandler>
										}
										key={'route' + index}
									></Route>
								);
							} else if (item.authType === 'auth') {
								return (
									<Route
										path={item.route}
										element={
											<RequireAuth>
												<RoutesLayoutHandler layout={item.layout}>
													<item.component />
												</RoutesLayoutHandler>
											</RequireAuth>
										}
										key={'route' + index}
									/>
								);
							} else if (item.authType === 'no-auth') {
								return (
									<Route
										path={item.route}
										element={
											<RequireNoAuth>
												<RoutesLayoutHandler layout={item.layout}>
													<item.component />
												</RoutesLayoutHandler>
											</RequireNoAuth>
										}
										key={'route' + index}
									/>
								);
							}
						})}

						<Route
							path=':lng/account/saving'
							element={
								<RequireAuth>
									<SavingHistory />
								</RequireAuth>
							}
						/>

						<Route path=':lng/nft' element={<NFTHome />} />
						<Route path=':lng/nft/marketplace' element={<NFTMarketplace />} />
						<Route path=':lng/nft/product/:productId' element={<NFTProduct />} />
						<Route path=':lng/nft/collection/:collectionId' element={<NFTCollectionDetail />} />
						<Route path=':lng/nft/profile/:username' element={<NFTUSersProfile />} />

						{/* TO-DO: remove this comment and all related file due to redesign dashboard and changes the route */}
						{/* <Route
							path=':lng/dashboard'
							element={
								<RequireAuth>
									<Dashboard />
								</RequireAuth>
							}
						/> */}

						<Route
							path=':lng/referral'
							element={
								<RequireAuth>
									<Referral />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/referees'
							element={
								<RequireAuth>
									<Referees />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/security'
							element={
								<RequireAuth>
									<Security />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/supportTicket'
							element={
								<RequireAuth>
									<SupportTicket />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/activity-log'
							element={
								<RequireAuth>
									<ActivityLog />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/notification'
							element={
								<RequireAuth>
									<Notificaiton />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/VIP'
							element={
								<RequireAuth>
									<VIP />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/balances'
							element={
								<RequireAuth>
									<Balances />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/small-balances'
							element={
								<RequireAuth>
									<SmallBalances />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/rewards'
							element={
								<RequireAuth>
									<Quests />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/fee-rates'
							element={
								<RequireAuth>
									<FeeRates />
								</RequireAuth>
							}
						/>

						<Route
							path=':lng/profile/edit'
							element={
								<RequireAuth>
									<EditProfile />
								</RequireAuth>
							}
						/>
						<Route
							path=':lng/KYC'
							element={
								<RequireAuth>
									<DashbordLayout />
								</RequireAuth>
							}
						>
							<Route element={<KYC />} index />
							<Route path='corporation' element={<KYCCorporation />} />
							<Route path='corporation/basic' element={<KYCBasicInformation />} />
							<Route path='corporation/documents' element={<KYCUploadDocument />} />
						</Route>
						<Route
							path=':lng/partner'
							element={
								<RequireAuth>
									<DashbordLayout />
								</RequireAuth>
							}
						>
							<Route path='information' element={<PartnerInformation />} />
							<Route path='credentials' element={<PartnerCredentials />} />
							<Route path='deposits' element={<PartnerDeposit />} />
							<Route path='transfers' element={<PartnerTransfer />} />
							<Route path='Clients' element={<PartnerClients />} />
						</Route>
						<Route path=':lng/FAQ' element={<FAQ />}>
							<Route path='getting-started' element={<GettingStarted />} />
							<Route path='getting-started/:section' element={<GettingStarted />} />
							<Route path='deposit-withdraw' element={<DepositWithdraw />} />
							<Route path='security' element={<FAQSecurity />} />
							<Route path='hot-issue' element={<HotIssue />} />
							<Route path='futures' element={<FuturesFaq />} />
							<Route path='app-spot' element={<AppSpot />} />
							<Route path='earn' element={<FAQEarn />} />
							<Route path='cross-margin' element={<FAQCrossMargin />} />
						</Route>
						<Route
							path=':lng/history'
							element={
								<RequireAuth>
									<DashbordLayout />
								</RequireAuth>
							}
						>
							<Route path='deposit' element={<DepositHistory />} />
							<Route path='withdraw' element={<WithdrawsHistory />} />
							<Route path='fiat' element={<FiatHistory />} />
							<Route path='savings' element={<EarnHistory />} />
							<Route path='spot' element={<SpotHistory />} />
							<Route path='futures' element={<FuturesHistory />} />
							<Route path='transfer' element={<TransferHistory />} />
						</Route>
						<Route
							path=':lng/overview'
							element={
								<RequireAuth>
									<DashbordLayout />
								</RequireAuth>
							}
						>
							<Route path='futures' element={<FuturesOverView />} />
						</Route>

						<Route
							path=':lng/nft'
							element={
								<RequireAuth>
									<NFTDashboardLayout />
								</RequireAuth>
							}
						>
							<Route path='profile' element={<EditProfileBody />} />
							<Route path='wallet' element={<NFTWallet />} />
							<Route path='deposit' element={<NFTDeposit />} />
							<Route path='withdraw' element={<NFTWithdraw />} />
							<Route path='history/deposit' element={<NFTDepositHistory />} />
							<Route path='history/widthraw' element={<NFTWidthrawHistory />} />
							<Route path='history/bidding' element={<NFTBiddingHistory />} />
							<Route path='history/purchase' element={<NFTPurchaseHistory />} />
							<Route path='history/sales' element={<NFTSalesHistory />} />
							<Route path='favorites' element={<NFTFavorit />} />
							<Route path='collected' element={<NFTCollected />} />
						</Route>
					</Routes>
					{userSelectedLanguage === null && isLogin && <ProfileLangModal status={userSelectedLanguage === null} />}
					<AppModal />
				</Suspense>
			</ThemeHandler>
		</BrowserRouter>
	);
}

export default App;
