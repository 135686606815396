import { createSlice } from '@reduxjs/toolkit';

export const FirebaseStates = createSlice({
	name: 'Firebase',
	initialState: {
		fcmToken: localStorage.getItem('fcmToken') || '',
	},
	reducers: {
		changeFcmToken: (state, action) => {
			state.fcmToken = action.payload;
		},
	},
});

export const { changeFcmToken } = FirebaseStates.actions;

export default FirebaseStates.reducer;
