import React from 'react';
import { MobilePopUpContainer } from '../../assets/css/layout/mobile-popup';

function MobilePopUp(props) {
	const { isActive, setActive, style, bodyStyle } = props;
	const BGClickHandler = () => {
		setActive(false);
	};

	return window.innerWidth > 850 ? (
		props.children
	) : (
		<MobilePopUpContainer className={isActive ? 'active' : ''} style={style}>
			<div className='background_blur' onClick={BGClickHandler}></div>
			<div className='data' style={bodyStyle}>
				{props.children}
			</div>
		</MobilePopUpContainer>
	);
}

export default MobilePopUp;
