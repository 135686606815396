import styled from '@emotion/styled';

export const MainField = styled.div({
	display: 'flex',
	width: '100%',

	'@media (max-width: 1200px)': {
		marginBottom: '0px',
	},
	'@media (max-width: 900px)': {
		marginBottom: '-50px',
	},
});

export const ContentField = styled.div(props => ({
	width: '100%',
	padding: '16px',
	background: props.theme.mode === 'light' ? 'var(--color-primary-background-light-base)' : 'var(--color-secondary-background-dark-base)',
}));
