import ReactDOM from 'react-dom/client';
import App from './component/app/app';
import Store from './state-manager/store';
import { Provider } from 'react-redux';
import './assets/css/general/general.css';
import './assets/css/general/fontAwsome.css';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={Store}>
		<App />
	</Provider>,
);
