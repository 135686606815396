import { createSlice } from '@reduxjs/toolkit';

export const Profile = createSlice({
	name: 'userInfo',
	initialState: {
		email: '',
		phoneNumber: '',
		role: '---',
		haveTicket: 0,
		haveNotife: 0,
		isLogin: localStorage.getItem('refresh_auth_token') !== null ? true : false,
		haveChange: false,
		vipLevelName: '',
		bnbBurnForFeesEnabled: '',
		isFreezed: false,
		futuresEnabled: localStorage.getItem('futuresEnabled') === 'true' ? true : false || false,
		blvtEnabled: localStorage.getItem('blvtEnabled') === 'true' ? true : false || false,
		questRewardAvailable: localStorage.getItem('questRewardAvailable') === 'true' ? true : false || false,
		topMobileDownloadSkipped: localStorage.getItem('topMobileDownloadSkipped') === 'true' ? true : false || false,
		modalMobileDownloadSkipped: localStorage.getItem('modalMobileDownloadSkipped') === 'true' ? true : false || false,
		tournamentBattleModal: localStorage.getItem('tournamentBattleModal') ? localStorage.getItem('tournamentBattleModal') : 0,
		language: '',
		userName: '',
		referralType: '',
		lastLoginActivity: {
			createdAt: '',
			ipAddress: '',
		},
		securityActions: {
			login: [],
			actions: [],
			notifications: [],
		},
		advanceKyc: {
			rejectionReason: null,
			status: null,
		},
		basicKyc: {
			rejectionReason: null,
			status: null,
		},
		corporationKyc: {
			rejectionReason: null,
			status: null,
		},
	},
	reducers: {
		GetInfo: (state, action) => {
			state.email = action.payload.email;
			state.phoneNumber = action.payload.phoneNumber;
			state.role = action.payload.role;
			state.haveTicket = action.payload.adminAnsweredTickets;
			state.vipLevelName = action.payload.vipLevelName;
			state.lastLoginActivity = action.payload.lastLoginActivity;
			state.bnbBurnForFeesEnabled = action.payload.bnbBurnForFeesEnabled;
			state.corporationKyc = action.payload.corporationKyc;
			state.advanceKyc = action.payload.advanceKyc;
			state.basicKyc = action.payload.basicKyc;
			state.futuresEnabled = action.payload.futuresEnabled;
			state.questReward = action.payload.questReward;
			state.userName = action.payload.userName;
			state.isFreezed = action.payload.isFreezed;
			state.referralType = action.payload.referral.type;
			state.language = action.payload.language;
			localStorage.setItem('futuresEnabled', action.payload.futuresEnabled ? 'true' : 'false');

			state.blvtEnabled = action.payload.blvtEnabled;
			localStorage.setItem('blvtEnabled', action.payload.blvtEnabled ? 'true' : 'false');

			state.questRewardAvailable = action.payload.questRewardAvailable;
			localStorage.setItem('questRewardAvailable', action.payload.questRewardAvailable ? 'true' : 'false');

			localStorage.setItem('profile-lang', action.payload.language);
		},
		EnableFutures: (state, action) => {
			state.futuresEnabled = true;
			localStorage.setItem('futuresEnabled', 'true');
		},
		UpdateBLVT: (state, action) => {
			state.blvtEnabled = action.payload;
			localStorage.setItem('blvtEnabled', action.payload ? 'true' : 'false');
		},
		UpdateQuestRewardAvailable: (state, action) => {
			state.questRewardAvailable = action.payload;
			localStorage.setItem('questRewardAvailable', action.payload ? 'true' : 'false');
		},
		getSecurityStrategies: (state, action) => {
			state.securityActions.actions = action.payload.actions;
			state.securityActions.login = action.payload.login;
			state.securityActions.notifications = action.payload.notification;
		},
		IsLogin: (state, action) => {
			state.isLogin = action.payload;
		},
		changeTicketStatus: (state, action) => {
			state.haveTicket = action.payload;
		},
		haveChange: (state, action) => {
			state.haveChange = action.payload;
		},
		setNotif: (state, action) => {
			state.haveNotife = action.payload;
		},
		changeMobileDownloadStatus: (state, action) => {
			if (action.payload.type === 'modal') {
				state.modalMobileDownloadSkipped = true;
				localStorage.setItem('modalMobileDownloadSkipped', 'true');
			} else {
				state.topMobileDownloadSkipped = true;
				localStorage.setItem('topMobileDownloadSkipped', 'true');
			}
		},
		changeTournamentBattleModal: (state, action) => {
			localStorage.setItem('tournamentBattleModal', new Date().getTime());
			state.tournamentBattleModal = new Date().getTime();
		},
		changeProfileLanguage: (state, action) => {
			state.language = action.payload;
		},
	},
});

export const {
	GetInfo,
	EnableFutures,
	UpdateBLVT,
	UpdateQuestRewardAvailable,
	IsLogin,
	getSecurityStrategies,
	changeTicketStatus,
	haveChange,
	setNotif,
	changeMobileDownloadStatus,
	changeTournamentBattleModal,
	changeProfileLanguage,
} = Profile.actions;
export default Profile.reducer;
