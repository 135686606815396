import RequestHandler from '../../customize/requestHandler';

export const GetCampaign = campaign => {
	return RequestHandler.call({ url: `/api/v1/referrals/campaigns/${campaign}`, method: 'get' }).then(res => res.data.result);
};

export const RegisterApi = async data => {
	return RequestHandler.call({ url: '/api/v1/users', method: 'post', data }).then(res => res.data.result[0]);
};

export const OTPApi = async data => {
	return RequestHandler.call({ url: '/api/v1/otps', method: 'post', data }).then(res => res.data.result);
};

export const LoginApi = async data => {
	return RequestHandler.call({ url: '/api/v1/users/session', method: 'post', data }).then(res => res.data.result[0]);
};

export const ForgetPasswordAPI = async data => {
	return RequestHandler.call({ url: '/api/v1/users/password', method: 'put', data }).then(res => res.data);
};

export const GenerateNewAccessToken = async data => {
	return RequestHandler.call({ url: '/api/v1/users/session', method: 'put', data }).then(res => res.data.result[0]);
};
export const LogoutAPI = async () => {
	return RequestHandler.call({ url: '/api/v1/users/session', method: 'delete' }).then(res => res.data.result);
};

export const GetUser = async data => {
	return RequestHandler.call({ url: '/api/v1/users', method: 'post', data }).then(res => res.data.result);
};

export const GetCredentials = async () => {
	return RequestHandler.call({ url: '/api/v1/users/credentials', method: 'get' }).then(res => res);
};
export const EnableFutures = async () => {
	return RequestHandler.call({ url: '/api/v1/users/credentials/futures', method: 'put' }).then(res => res);
};

export const GetSession = async data => {
	return RequestHandler.call({ url: '/api/v1/users/session', method: 'post', data }).then(res => res.data.result);
};
export const GetServiceSession = async () => {
	return RequestHandler.call({ url: '/api/v1/services/session', method: 'post' }).then(res => res.data.result);
};
export const GetUserReferralInformation = async () => {
	return RequestHandler.call({ url: '/api/v1/referrals/information', method: 'get' }).then(res => res.data.result);
};
