import { FC, ReactNode, memo, useEffect, useState } from 'react';
import { Global } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Footer, Navbar } from '@coinlocally/cly-core';

import { themProvider, themeCashProvider } from 'state-manager/reducer/them';

import { GetAnnouncement, getNotification, viewNotification } from 'api-requests/notification/notification';
import { LogoutAPI } from 'api-requests/auth/api-call';

import requestHandler from 'customize/requestHandler';
import RequestHandler from 'utils/requestHandler';

import { IsLogin } from 'state-manager/reducer/profile';
import maskEmail from 'utils/mask-email';
import maskPhone from 'utils/mask-phone';
import { getAssets } from 'api-requests/buy-crypto/buy-crypto';
import { useAppSelector } from 'hooks/redux-helper';
import ContactUs from 'component/other/contact-us';

const Layout: FC<{ children: ReactNode; footerDisplay: boolean; forceLight: boolean }> = ({
	children,
	footerDisplay = true,
	forceLight,
}) => {
	const Theme = useSelector((state: any) => state.ThemeProvider.theme);
	const { isLogin, email, phoneNumber, vipLevelName, corporationKyc, advanceKyc, basicKyc, referralType } = useAppSelector(
		state => state.Profile,
	);
	const isMobile = useAppSelector(state => state.Home.isMobile);
	const language = useTranslation().i18n.language;
	const dispatch = useDispatch();

	const [notification, setNotification] = useState<null | any[]>(null);
	const [announcement, setAnnouncement] = useState<null | []>(null);
	const [buyCryptoAssets, setBuyCryptoAssets] = useState<{ name: string; icon: string }[]>([]);

	const [contactUsModalStatus, setContactUsModalStatus] = useState<boolean>(false);

	const changeTheme = () => {
		dispatch(themProvider(Theme === 'light' ? 'dark' : 'light'));
		dispatch(themeCashProvider(Theme === 'light' ? 'dark' : 'light'));
	};

	useEffect(() => {
		if (!isMobile) {
			if (isLogin) {
				getNotification().then(res => {
					setNotification(res.result);
				});
			}
			GetAnnouncement().then(res => {
				setAnnouncement(res);
			});
			getAssets().then(res => {
				let assets = res.fiat.map(fiat => {
					return {
						name: fiat,
						icon: res.config.icons[fiat].png,
					};
				});
				setBuyCryptoAssets(assets);
			});
		}
		// eslint-disable-next-line
	}, []);

	const onNotificationSeenHandler = (notificationId: number) => {
		if (notification) {
			viewNotification(notificationId);

			let notifications = [...notification];
			let index = notifications.findIndex(item => item.id === notificationId);
			if (index !== -1) {
				notifications[index].viewed = true;
				setNotification(notifications);
			}
		}
	};

	const LogOutClickHandler = () => {
		LogoutAPI().then(() => {
			requestHandler.clearHeader();
			RequestHandler.clearHeader();
			localStorage.removeItem('refresh_auth_token');
			localStorage.removeItem('access_auth_token');
			dispatch(IsLogin(false));
			window.location.reload();
		});
	};

	const openChatHandler = () => {
		document.querySelector<HTMLElement>('[data-id]')?.click();
	};
	const onContactUsClickHandler = () => {
		setContactUsModalStatus(true);
	};

	const profileData: {
		user: string;
		kyc: {
			isVerified: boolean;
			kycLevel: 'Basic' | 'Advanced' | 'Corporation';
		};
		vipLevel: string;
	} | null = !isLogin
		? null
		: {
				kyc: {
					isVerified:
						corporationKyc.status === 'CONFIRMED' || advanceKyc.status === 'CONFIRMED' || basicKyc.status === 'CONFIRMED',
					kycLevel:
						corporationKyc.status === 'CONFIRMED' ? 'Corporation' : advanceKyc.status === 'CONFIRMED' ? 'Advanced' : 'Basic',
				},
				user: email ? maskEmail(email) : maskPhone(phoneNumber!),
				vipLevel: vipLevelName ?? '',
		  };
	return (
		<>
			<Global
				styles={{
					body: {
						backgroundColor: Theme === 'dark' ? '#1e2328' : 'white',
					},
					nav: {
						'*': {
							fontFamily: 'var(--font-product-sans) !important',
						},
					},
					'& .MuiDialog-paperFullWidth': {
						backgroundColor: Theme === 'dark' ? '#1E2328 !important' : 'white',
						backgroundImage: Theme === 'dark' ? 'none !important' : '',
					},
				}}
			/>
			{!isMobile && (
				<Navbar
					theme={Theme}
					referralType={referralType}
					type='FULL'
					isLinkExternal={false}
					isLogin={isLogin}
					language={language}
					changeTheme={changeTheme}
					buyCryptoCurrencies={buyCryptoAssets}
					announcements={announcement}
					notifications={notification}
					profile={profileData}
					onNotificationSeenHandler={onNotificationSeenHandler}
					onLogOutClickHandler={LogOutClickHandler}
				/>
			)}
			{children}
			{!isMobile && footerDisplay && <Footer onChatClick={openChatHandler} onContactUs={onContactUsClickHandler} />}
			{contactUsModalStatus && (
				<ContactUs
					closeContactUs={() => {
						setContactUsModalStatus(false);
					}}
					status={contactUsModalStatus}
				/>
			)}
		</>
	);
};

export default memo(Layout);
