import styled from '@emotion/styled';

export const AppModalContainer = styled.div(props => ({
	padding: '32px 24px',
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
	alignItems: 'center',

	h3: {
		fontFamily: 'var(--font-oswald) !important',
		fontSize: '1.25rem',
		fontWeight: '700',
		marginBottom: '0',
	},

	p: {
		color: 'var(--color-text-3)',
		marginBottom: '8px',
	},

	'& .modal_action_button': {
		width: '100%',
	},
}));
