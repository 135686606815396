import styled from '@emotion/styled';
import OptionUnstyled from '@mui/base/Option';
import optionUnstyledClasses from '@mui/base/Option/optionClasses';
import selectUnstyledClasses from '@mui/base/Select/selectClasses';
import { styled as muiStyled } from '@mui/system';
import { Popper as PopperUnstyled } from '@mui/base';

export const Input = styled.input(props => ({
	width: '100%',
	border: '0',
	border: props.theme.mode === 'light' ? '1px solid #e4e4e4' : 'none',
	height: '40px',
	borderRadius: '3px',
	padding: '0px 10px',
	color: props.theme.mode === 'light' ? '#6c7681' : 'white',
	marginTop: '5px',
	fontSize: '1rem',
	backgroundColor: props.theme.mode === 'light' ? 'white' : '#323b44',

	'&.border_less': {
		border: 'none',
		backgroundColor: 'transparent',
	},
}));

export const Textarea = styled.textarea(props => ({
	width: '100%',
	height: '180px',
	border: '0',
	border: props.theme.mode === 'light' ? '1px solid #e4e4e4' : 'none',
	borderRadius: '3px',
	padding: '10px',
	color: props.theme.mode === 'light' ? '#6c7681' : 'white',
	marginTop: '5px',
	fontSize: '1rem',
	backgroundColor: props.theme.mode === 'light' ? 'white' : '#323B44',
}));

export const InputGroup = styled.div({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',

	'& .showPassword': {
		width: '37px',
		fontSize: '1.1rem',
		color: ' #c5c8d1',
		position: 'absolute',
		right: '5px',
		top: '45px',
	},
});

export const InlineField = styled.div(props => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '10px',

	label: {
		display: 'none',
	},

	legend: {
		display: 'none',
	},

	'& .MuiOutlinedInput-root': {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
		background: '#323b44',
		height: '40px',
		position: 'relative',
		top: '-4px',

		'&:hover': {
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid #E4E4E4',
			},
		},
	},

	fieldset: {
		top: '0px',
	},

	'& .MuiOutlinedInput-notchedOutline': {
		border: props.theme.mode === 'light' ? '1px solid #E4E4E4' : 'none',
		height: '40px',
	},

	'& .MuiAutocomplete-endAdornment': {
		top: 'calc(50% - 18px) !important',
	},

	span: {
		fontSize: '0.8rem',
	},

	'& .MuiOutlinedInput-input': {
		padding: '3.5px 4px 7.5px 6px !important',
	},

	'& .MuiPaper-root': {
		width: '250px',
		marginTop: '10px',
	},

	'& .MuiOutlinedInput-root ': {
		backgroundColor: 'white',
	},
}));

export const CustomSelectField = styled.div(props => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '10px',

	'& .MuiSelectUnstyled-popper': {
		inset: '8px auto auto 0px !important',
		boxShadow: 'rgb(99 99 99 / 20%) 0px 2px 8px 0px',
		width: '100%',
	},

	button: {
		minWidth: '100%',
		backgroundColor: props.theme.mode === 'light' ? 'white' : '#323B44',
		border: `1px solid ${props.theme.mode === 'light' ? '#E4E4E4' : 'transparent'}`,
		color: props.theme.palette.blackAndWhite,
	},
}));

export const Label = styled.label(props => ({
	margin: '0px',
	fontSize: '.9rem',
	lineHeight: '16px',
	marginTop: '20px',
	color: props.theme.mode === 'light' ? '#474d57' : 'white',
}));

export const InputButton = styled.button(props => ({
	width: 'max-content',
	position: 'absolute',
	right: '8px',
	top: '47px',
	backgroundColor: 'transparent !important',
	color: '#d9ae00',
	background: '#ebebeb',
	padding: '3px 10px',
	borderRadius: '3px',
}));

export const StyledButton = muiStyled('button')`
	font-size: 0.875rem;
	max-height: 40px;
	min-width: 110px;
	max-width: 110px;
	background: #fff;
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	padding: 10px;
	text-align: left;
	color: #646464;
	height: 40px;
	position: relative;
	top: 2px;
	padding-right: 13px;
	display: flex;
	align-items: center;

	div {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&.${selectUnstyledClasses.focusVisible} {
		outline: 4px solid rgba(100, 100, 100, 0.3);
	}

	&.${selectUnstyledClasses.expanded} {
		&::after {
			content: '▴';
		}
	}

	&::after {
		content: '▾';
		position: absolute;
		right: 3px;
		top: 8px;
	}

	& img {
		margin-right: 10px;
        position: relative;
        top: 2px;
	}
`;

export const StyledListbox = muiStyled('ul')`
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    background-color: #fff;
    min-width: 200px;
    border: 1px solid #cbcbcb;
    color: #494960;
    max-height: 200px;
    overflow: auto;
    border-radius: 4px;
`;

export const StyledOption = muiStyled(OptionUnstyled)`
    list-style: none;
    padding: 15px;
    margin: 0;
    cursor: default;

	&:last-of-type {
		border-bottom: none;
	}

	&.${optionUnstyledClasses.disabled} {
		color: #888;
	}

	&.${optionUnstyledClasses.selected} {
		background-color: rgba(25, 118, 210, 0.08);
	}

	&.${optionUnstyledClasses.highlighted} {
		background-color: #16d;
		color: #fff;
	}

	&.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
		background-color: #0075ee;
		color: #fff !important;
	}

	&:hover:not(.${optionUnstyledClasses.disabled}) {
		background-color: #39e;
		color: #fff !important;
	}

	& img {
		margin-right: 10px;
        position: relative;
        top: 2px;
	}
`;

export const StyledPopper = muiStyled(PopperUnstyled)`
	z-index: 1;
`;

export const SearchInputField = styled.div(props => ({
	maxWidth: '300px',
	position: 'relative',
	minWidth: '200px',
	display: 'flex',
	alignItems: 'center',
	height: 'max-content',

	input: {
		paddingLeft: '35px',
		fontSize: '0.9rem',
		backgroundColor: props.theme.palette.assetsBg,
		border: 'none',
	},

	i: {
		position: 'absolute',
		left: '0px',
		color: '#9b9b9b',
		top: '11px',
		cursor: 'pointer',
		width: '32px',
		height: '29px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	'@media (max-width: 600px)': {
		width: '100%',
		maxWidth: 'inherit',
	},
}));

export const Small = styled.small({
	marginTop: '7px',
	color: '#9e9dab',
	textAlign: 'left !important',
});

export const SelectField = styled.div(props => ({
	position: 'relative',
	width: '100%',
	marginTop: '20px',

	'&:first-of-type': {
		margin: '0',
	},

	'&:after': {
		content: "''",
		height: '21px',
		width: '1px',
		background: '#ebeaef',
		position: 'absolute',
		right: '120px',
		top: '39px',
	},

	'& .MuiAutocomplete-hasPopupIcon': {
		position: 'absolute',
		top: '30px',
		right: '1px',
		backgroundColor: props.theme.mode === 'light' ? 'white' : '#323B44',
		width: '120px',
	},
	'& .MuiInputLabel-formControl': {
		display: 'none',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		border: 'none !important',
	},
	'& .MuiInputBase-root': {
		border: 'none',
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
		paddingRight: '50px !important',
		borderRadius: '0',
	},
}));

export const InputTitle = styled.div(props => ({
	position: 'absolute',
	right: '0',
	top: '15px',
	fontSize: '0.9rem',
	color: props.type === 'Weak' ? '#ff7f7f' : '#0ecb81',
}));
