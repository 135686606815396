import React from 'react';
import LoadingAnimation from '../../assets/jsone/loader.json';
import Lottie from 'react-lottie-player';

//assets
import { LoaderTMP } from '../../assets/css/layout/loader';

function Loader() {
	return (
		<LoaderTMP>
			<Lottie loop animationData={LoadingAnimation} play style={{ width: 250, height: 250 }} />
		</LoaderTMP>
	);
}

export default Loader;
