import { createSlice } from '@reduxjs/toolkit';

export const Market = createSlice({
	name: 'Futures',
	initialState: {
		SpotPair: [],
		FuturesPair: [],
		AllCrypto: [],
		MarketType: 'Spot Market',
		lazyLoadPage: 1,
	},
	reducers: {
		UpdateSpot: (state, action) => {
			if (action.payload.type === 'init') {
				state.SpotPair = action.payload.data;
			} else if (action.payload.type === 'Update') {
				let prev = state.SpotPair;
				let update = action.payload.data;
				for (let i = 0; i < update.length; i++) {
					const findItem = prev.findIndex(item => item.symbol === update[i].symbol);
					if (findItem !== -1) {
						prev[findItem].lastPrice = update[i].lastPrice;
						prev[findItem].volume24h = update[i].volume24h;
						prev[findItem].turnover24h = update[i].turnover24h;
						prev[findItem].price24hPercentage = update[i].price24hPcnt;
					}

					// }
					state.SpotPair = prev;
				}
				
			}
		},

		UpdateFutures: (state, action) => {
			if (action.payload.type === 'init') {
				state.FuturesPair = action.payload.data;
			} else {
				// if (state.MarketType === 'Futures Market') {
				let prev = state.FuturesPair;
				let update = action.payload.data;
				for (let i = 0; i < update.length; i++) {
					const findItem = prev.findIndex(item => item.symbol === update[i].symbol);
					if (findItem !== -1) {
						prev[findItem].lastPrice = update[i].lastPrice;
						prev[findItem].volume24h = update[i].volume24h;
						prev[findItem].turnover24h = update[i].turnover24h;
						prev[findItem].price24hPercentage = update[i].price24hPcnt;
						prev[findItem].openInterest = update[i].openInterest;
					}
				}
				state.FuturesPair = prev;
				// }
			}
		},

		UpdateAllCrypto: (state, action) => {
			if (action.payload.type === 'init') {
				state.AllCrypto = action.payload.data;
			}
		},

		UpdateMarketType: (state, action) => {
			state.MarketType = action.payload;
		},

		UpdateLazyLoadPage: (state, action) => {
			if (action.payload.type === 'increament') {
				state.lazyLoadPage = state.lazyLoadPage + 1;
			} else if (action.payload.type === 'reset') {
				state.lazyLoadPage = 1;
			}
		},
	},
});

export const { UpdateSpot, UpdateFutures, UpdateAllCrypto, UpdateMarketType, UpdateLazyLoadPage } = Market.actions;
export default Market.reducer;
