import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

// assets
import { ButtonGroup, InputField, Header } from '../../assets/css/other/contact-us';
import Img from '../../assets/img/message.svg';

// api request
import { contactUs } from '../../api-requests/layout/contact-us';

// component
import { SimpleInput, TextareaInput } from '../../customize/input';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function ContactUs(props) {
	const { t } = useTranslation('contact_us');
	const handleLoaded = function (cb) {
		window.grecaptcha.ready(_ => {
			window.grecaptcha.execute('6Lf3Q4AfAAAAAEkSsadlqXxe-v68QgLDOT3iupyP', { action: 'login' }).then(token => {
				cb && cb(token);
			});
		});
	};

	const [inputValue, setInputValue] = useState({
		email: '',
		name: '',
		phone: '',
		subject: '',
		message: '',
	});

	const getValue = e => {
		if (e.target.name === 'phoneNumber' || e.target.name === 'referralId' || e.target.name === 'otp')
			if (!isNaN(e.target.value))
				setInputValue({
					...inputValue,
					[e.target.name]: e.target.value,
				});
			else return false;
		else
			setInputValue({
				...inputValue,
				[e.target.name]: e.target.value,
			});
	};

	const sendContactUs = () => {
		handleLoaded(recaptchaToken => {
			const newInputValue = {
				...inputValue,
				recaptchaToken: recaptchaToken,
			};
			contactUs(newInputValue).then(() => {
				props.closeContactUs();
			});
		});
	};

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://www.google.com/recaptcha/api.js?render=6Lf3Q4AfAAAAAEkSsadlqXxe-v68QgLDOT3iupyP';
		document.body.appendChild(script);
	}, []);

	return (
		<div>
			<div className='g-recaptcha' data-sitekey='6Lf3Q4AfAAAAAEkSsadlqXxe-v68QgLDOT3iupyP' data-size='invisible'></div>
			<Dialog
				open={props.status ?? false}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.closeContactUs}
				maxWidth='xs'
				fullWidth={true}
				scroll='body'
			>
				<Header>
					<img src={Img} alt='' />
					<h3>{t('title')}</h3>
					<p>{t('description')}</p>
				</Header>
				<DialogContent>
					<InputField>
						<SimpleInput label={t('name')} type='text' value={inputValue.name} getVal={getValue} name='name' />
						<SimpleInput label={t('email')} type='text' value={inputValue.email} getVal={getValue} name='email' />
						<SimpleInput label={t('subject')} type='text' value={inputValue.subject} getVal={getValue} name='subject' />
						<TextareaInput label={t('message')} type='text' value={inputValue.message} getVal={getValue} name='message' />
					</InputField>
				</DialogContent>
				<DialogActions>
					<ButtonGroup>
						<Button onClick={props.closeContactUs}>{t('close')}</Button>
						<Button onClick={sendContactUs}>{t('send')}</Button>
					</ButtonGroup>
				</DialogActions>
			</Dialog>
		</div>
	);
}
