import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalMainContainer = styled.div(
	({ theme }) => css`
		padding: 32px 40px;
		min-width: 478px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 32px;
		flex-direction: column;
		position: relative;

		* {
			font-family: 'Product Sans' !important;
		}

		.header_img {
			width: 206px;
			height: 160px;
		}

		h3 {
			margin-bottom: 20px;
			color: ${theme.colors.text.primary};
			font-size: 24px;
			font-weight: 700;
			line-height: 28px;
			text-align: center;
		}

		p {
			color: ${theme.colors.text.secondary};
			text-align: center;
			font-size: 20px;
			font-weight: 400;
			line-height: 28px;
			text-align: center;
			margin: 0;

			b {
				color: ${theme.colors.text.primary};
				font-weight: 700;
			}
		}

		.button_group {
			display: flex;
			align-items: center;
			gap: 8px;
			width: 100%;

			button {
				width: 100%;
			}

			a {
				width: 100%;
			}
		}

		.close_modal {
			position: absolute;
			top: 20px;
			right: 40px;
			width: 24px;
			height: 24px;
			color: ${theme.colors.text.primary};
			cursor: pointer;
		}

		@media (max-width: 500px) {
			min-width: unset;

			.header_img {
				width: 180px;
				height: 140px;
			}

			h3 {
				margin-bottom: 16px;
				font-size: 20px;
				line-height: 20px;
			}

			p {
				font-size: 16px;
				line-height: 21px;
			}
		}
	`,
);
