import { createSlice } from '@reduxjs/toolkit';
import { IHomePage } from 'state-manager/types';

const initialState: IHomePage = {
	FuturesPairs: [],
	SpotPairs: [],
	isMobile: localStorage.getItem('isMobile') ? (localStorage.getItem('isMobile') === 'true' ? true : false) : false,
	balanceVisible: localStorage.getItem('balanceVisible') ? (localStorage.getItem('balanceVisible') === 'true' ? true : false) : false,
	promotion: [
		{
			title: 'xmas',
			numberOfShows: localStorage.getItem('xmas-modal') ? JSON.parse(localStorage.getItem('xmas-modal')!).numberOfShows : 100,
			lastVisited: localStorage.getItem('xmas-modal') ? JSON.parse(localStorage.getItem('xmas-modal')!).lastVisited : 0,
			startsAt: 1701783923000,
			endsAt: 1705708800000,
			showTimeInMillisecond: 21600000,
		},
	],
};

export const Home = createSlice({
	name: 'Home',
	initialState: initialState,
	reducers: {
		changeFuturesPairs: (state, action) => {
			if (action.payload.type === 'init') {
				let pairs = action.payload.data.map((item: any) => {
					return {
						baseCoin: item.baseCoin,
						quoteCoin: item.quoteCoin,
						symbol: item.symbol,
						createdAt: item.launchTime,
						lastPrice: item.lastPrice,
						price24hPercentage: item.price24hPercentage,
						turnover24h: item.turnover24h,
					};
				});
				state.FuturesPairs = pairs;
			} else {
				let data = action.payload.data;
				for (let index = 0; index < state.FuturesPairs.length; index++) {
					let newData = data.find((item: any) => item.symbol === state.FuturesPairs[index].symbol);
					if (newData) {
						state.FuturesPairs[index].lastPrice = newData.lastPrice;
						state.FuturesPairs[index].turnover24h = newData.turnover24h;
						state.FuturesPairs[index].price24hPercentage = newData.price24hPcnt;
					}
				}
				state.FuturesPairs = state.FuturesPairs.filter(item => data.map((d: any) => d.symbol).includes(item.symbol));
			}
		},

		changeSpotPairs: (state, action) => {
			if (action.payload.type === 'init') {
				let pairs = action.payload.data.map((item: any) => {
					return {
						baseCoin: item.baseCoin,
						quoteCoin: item.quoteCoin,
						symbol: item.symbol,
						createdAt: item.createdAt,
						lastPrice: item.lastPrice,
						price24hPercentage: item.price24hPercentage,
						turnover24h: item.turnover24h,
					};
				});
				state.SpotPairs = pairs;
			} else {
				let data = action.payload.data;
				for (let index = 0; index < state.SpotPairs.length; index++) {
					let newData = data.find((item: any) => item.symbol === state.SpotPairs[index].symbol);
					if (newData) {
						state.SpotPairs[index].lastPrice = newData.lastPrice;
						state.SpotPairs[index].turnover24h = newData.turnover24h;
						state.SpotPairs[index].price24hPercentage = newData.price24hPcnt;
					}
				}
				state.SpotPairs = state.SpotPairs.filter(item => data.map((d: any) => d.symbol).includes(item.symbol));
			}
		},

		changeIsMobileStatus: (state, action) => {
			state.isMobile = action.payload;
			localStorage.setItem('isMobile', action.payload ? 'true' : 'false');
		},

		changeBalanceVisibilityStatus: (state, action) => {
			state.balanceVisible = action.payload;
			localStorage.setItem('balanceVisible', action.payload ? 'true' : 'false');
		},

		changePromotionXmas: (state, action: { payload: Pick<IHomePage['promotion'][0], 'lastVisited' | 'numberOfShows'> }) => {
			state.promotion[0].lastVisited = action.payload.lastVisited;
			state.promotion[0].numberOfShows = action.payload.numberOfShows;
			localStorage.setItem('xmas-modal', JSON.stringify(action.payload));
		},
	},
});

export const { changeFuturesPairs, changeSpotPairs, changeIsMobileStatus, changeBalanceVisibilityStatus, changePromotionXmas } =
	Home.actions;
export default Home.reducer;
