import { configureStore } from '@reduxjs/toolkit';
import ThemeProvider from './reducer/them';
import Profile from './reducer/profile';
import BuyCrypto from './reducer/buy-crypto';
import ApiResult from './reducer/api-result';
import Loader from './reducer/loader';
import spotFavorite from './reducer/spot-favorite';
import FuturesStates from './reducer/futures';
import Market from './reducer/market';
import Firebase from './reducer/firebase';
import spot from './reducer/spot';
import Home from './reducer/home';
import BalanceVisibility from './reducer/balance-visibility';

export default configureStore({
	reducer: {
		ThemeProvider: ThemeProvider,
		Profile: Profile,
		BuyCrypto: BuyCrypto,
		ApiResult: ApiResult,
		Loader: Loader,
		SpotFavorite: spotFavorite,
		spot: spot,
		FuturesStates: FuturesStates,
		Market: Market,
		Home: Home,
		BalanceVisibility: BalanceVisibility,
		Firebase: Firebase,
	},
});
