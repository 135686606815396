export const RouteTheme: { route: string; theme: 'dark' | 'light' }[] = [
	{
		route: '/landing/xmas-cashback',
		theme: 'light',
	},
	{
		route: '/landing/3commas',
		theme: 'light',
	},
	{
		route: '/black-friday',
		theme: 'dark',
	},
];
