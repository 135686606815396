import { createSlice } from '@reduxjs/toolkit';

export const ApiResult = createSlice({
	name: 'buy-crypto-info',
	initialState: {
		result: '',
	},
	reducers: {
		getResult: (state, action) => {
			state.result = action.payload;
		},
		removeResult: state => {
			state.result = '';
		},
	},
});

export const { getResult, removeResult } = ApiResult.actions;
export default ApiResult.reducer;
