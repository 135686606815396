import styled from '@emotion/styled';

export const MobilePopUpContainer = styled.div(props => ({
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	width: '100%',
	zIndex: '10',
	pointerEvents: 'none',

	'& .background_blur': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.38)',
		pointerEvents: 'none',
		opacity: '0',
		transition: 'all 0.4s ease-in-out',
	},

	'& .data': {
		background: props.theme.mode === 'light' ? '#fff' : '#1E2328',
		position: 'absolute',
		bottom: '-100%',
		left: '0',
		width: '100%',
		maxHeight: '90vh',
		overflow: 'auto',
		zIndex: '2',
		borderRadius: '16px 16px 0px 0px',
		pointerEvents: 'none',
		transition: 'all 0.4s ease-in-out',
	},

	'&.active': {
		pointerEvents: 'initial',

		'& .background_blur': {
			pointerEvents: 'initial',
			opacity: '1',
		},

		'& .data': {
			pointerEvents: 'initial',
			bottom: '0',
		},
	},
}));
