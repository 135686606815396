import styled from '@emotion/styled';

export const ButtonGroup = styled.div(props => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	margin: '0 auto',
	marginBottom: '14px',
	padding: '0 20px',

	button: {
		width: '100%',
		textTransform: 'unset',
		padding: '5px 20px',
		borderRadius: '50px',

		'&:last-child': {
			backgroundColor: props.theme.palette.mainColors.accent,
			color: 'black',
			marginLeft: '10px',

			'&:hover': {
				backgroundColor: props.theme.palette.mainColors.accent_hover,
			},
		},
		'&:first-of-type': {
			backgroundColor: '#ff2f63',
			color: 'white',

			'&:hover': {
				backgroundColor: '#ff5978',
			},
		},
	},
}));

export const InputField = styled.div({
	padding: '0 10px',
});

export const Header = styled.div(props => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	marginTop: '20px',
	padding: '0 30px',

	img: {
		width: '120px',
	},

	h3: {
		fontSize: '1.5rem',
		marginTop: '10px',
	},

	p: {
		fontSize: '0.9rem',
		textAlign: 'center',
		color: props.theme.mode === 'light' ? 'black' : '#b1b1b1',
	},
}));
