import { createSlice } from '@reduxjs/toolkit';

export const FuturesStates = createSlice({
	name: 'Futures',
	initialState: {
		pairListStatus: false,
		calcStatus: false,
		favorites: JSON.parse(localStorage.getItem('futuresFavorites')) || [],
		openOrders: null,
		openPositions: null,
		markData: null,
		pairs: [],
		pairSetting: {
			leverage: null,
			marginType: null,
		},
		leverageBrackets: null,
		pairsMultiplier: null,
		referrals: null,
		priceAlertStatus: false,
		createPriceAlertStatus: false,
		selectedPriceClick: 0,
		orderBook: {
			sellDepthAmount: 0,
			buyDepthAmount: 0,
		},
		ratioData: {
			marginRatio: null,
			maintenanceMargin: null,
			marginBalance: null,
		},
		multiplier: {
			baseMultiplier: 4,
			quoteMultiplier: 1,
			tickSize: 0.1,
		},
		balances: {
			spot: {
				USDT: {
					free: null,
					locked: null,
				},
				BUSD: {
					free: null,
					locked: null,
				},
			},
			futures: {},
		},
	},
	reducers: {
		changePairListStatus: (state, action) => {
			state.pairListStatus = action.payload;
		},

		changePairs: (state, action) => {
			if (action.payload.type === 'init') {
				state.pairs = action.payload.data;
			} else {
				const data = action.payload.data;
				for (let i = 0; i < data.length; i++) {
					const findItem = state.pairs.findIndex(item => item.symbol === data[i].symbol);
					if (findItem !== -1) {
						state.pairs[findItem].lastPrice = data[i].lastPrice;
						state.pairs[findItem].indexPrice = data[i].indexPrice;
						state.pairs[findItem].markPrice = data[i].markPrice;
						state.pairs[findItem].prevPrice24h = data[i].prevPrice24h;
						state.pairs[findItem].price24hPercentage = data[i].price24hPcnt;
						state.pairs[findItem].highPrice24h = data[i].highPrice24h;
						state.pairs[findItem].lowPrice24h = data[i].lowPrice24h;
						state.pairs[findItem].prevPrice1h = data[i].prevPrice1h;
						state.pairs[findItem].openInterest = data[i].openInterest;
						state.pairs[findItem].openInterestValue = data[i].openInterestValue;
						state.pairs[findItem].turnover24h = data[i].turnover24h;
						state.pairs[findItem].volume24h = data[i].volume24h;
						state.pairs[findItem].fundingRate = data[i].fundingRate;
						state.pairs[findItem].nextFundingTime = data[i].nextFundingTime;
					}
				}
			}
		},
		ChangeMarkData: (state, action) => {
			state.markData = action.payload;
		},
		changePriceAlertModalStatus: (state, action) => {
			state.priceAlertStatus = action.payload;
		},
		changeCreatePriceAlertModalStatus: (state, action) => {
			state.createPriceAlertStatus = action.payload;
		},
		changeReferralsStatus: (state, action) => {
			state.referrals = action.payload;
		},
		changeLeverageBrackets: (state, action) => {
			state.leverageBrackets = action.payload;
		},
		changeCalcStatus: (state, action) => {
			state.calcStatus = action.payload;
		},
		changeSelectedPriceClick: (state, action) => {
			state.selectedPriceClick = action.payload;
		},
		AddToFavorties: (state, action) => {
			var prev = JSON.parse(localStorage.getItem('futuresFavorites')) || [];
			prev.push(action.payload);
			localStorage.setItem('futuresFavorites', JSON.stringify(prev));
			state.favorites = prev;
		},
		AddToFavortiesPatchRequest: (state, action) => {
			var prev = JSON.parse(localStorage.getItem('futuresFavorites')) || [];
			for (let i = 0; i < action.payload.length; i++) {
				prev.push(action.payload[i]);
			}
			localStorage.setItem('futuresFavorites', JSON.stringify(prev));
			state.favorites = prev;
		},
		RemoveFromFavorties: (state, action) => {
			var prev = JSON.parse(localStorage.getItem('futuresFavorites')) || [];
			prev = prev.filter(item => item !== action.payload);
			localStorage.setItem('futuresFavorites', JSON.stringify(prev));
			state.favorites = prev;
		},
		ChangeSpotBalance: (state, action) => {
			state.balances.spot = action.payload;
		},
		ChangeFuturesBalance: (state, action) => {
			state.balances.futures = action.payload;
		},
		AddFuturesBalance: (state, action) => {
			const prev = state.balances.futures.availableToWithdraw;
			state.balances.futures.availableToWithdraw = parseFloat(prev) + parseFloat(action.payload.value);
		},
		AddSpotBalance: (state, action) => {
			const prev = state.balances.spot[action.payload.type].free;
			state.balances.spot[action.payload.type].free = parseFloat(prev) + parseFloat(action.payload.value);
		},
		ChangeMultiplier: (state, action) => {
			state.multiplier = action.payload;
		},

		changeOpenOrder: (state, action) => {
			if (action.payload.type === 'init') {
				state.openOrders = action.payload.data;
			} else {
				const update = action.payload.data;
				if (action.payload.type === 'REMOVED') {
					state.openOrders = state.openOrders.filter(item => item.orderId !== update.orderId);
				} else if (action.payload.type === 'NEW') {
					state.openOrders.unshift(update);
				}
			}
		},
		changeOpenPositions: (state, action) => {
			if (action.payload.type === 'init') {
				state.openPositions = action.payload.data;
			} else {
				let position = action.payload.data;
				if (state.openPositions.find(p => p.symbol === position.symbol)) {
					if (position.side === 'None') {
						state.openPositions = state.openPositions.filter(p => p.symbol !== position.symbol);
					} else {
						let index = state.openPositions.findIndex(p => p.symbol === position.symbol);
						state.openPositions[index].autoAddMargin = position.autoAddMargin;
						state.openPositions[index].bustPrice = position.bustPrice;
						state.openPositions[index].cumRealisedPnl = position.cumRealisedPnl;
						state.openPositions[index].avgPrice = position.entryPrice;
						state.openPositions[index].leverage = position.leverage;
						state.openPositions[index].liqPrice = position.liqPrice;
						state.openPositions[index].markPrice = position.markPrice;
						state.openPositions[index].positionBalance = position.positionBalance;
						state.openPositions[index].positionIM = position.positionIM;
						state.openPositions[index].positionIdx = position.positionIdx;
						state.openPositions[index].positionMM = position.positionMM;
						state.openPositions[index].positionValue = position.positionValue;
						state.openPositions[index].side = position.side;
						state.openPositions[index].size = position.size;
						state.openPositions[index].stopLoss = position.stopLoss;
						state.openPositions[index].takeProfit = position.takeProfit;
						state.openPositions[index].trailingStop = position.trailingStop;
						state.openPositions[index].unrealisedPnl = position.unrealisedPnl;
					}
				} else {
					if (position.side !== 'None') {
						position.avgPrice = position.entryPrice;
						delete position.entryPrice;
						state.openPositions.unshift(position);
					}
				}
			}
		},
		ChangeMarginOfPosition: (state, action) => {
			let update = action.payload;
			for (let i = 0; i < update.length; i++) {
				state.openPositions.find(op => op.symbol === update[i].s).isolatedWallet = update[i].iw;
			}
		},
		changePairSetting: (state, action) => {
			if (action.payload.type === 'init') {
				state.pairSetting.leverage = action.payload.data.leverage;
				state.pairSetting.marginType = action.payload.data.marginType;
			} else if (action.payload.type === 'marginUpdate') {
				state.pairSetting.marginType = action.payload.marginType;
			} else if (action.payload.type === 'leverageUpdate') {
				state.pairSetting.leverage = action.payload.leverage;
			}
		},
		changePairsMultipliers: (state, action) => {
			let pairs = action.payload;
			let Multipliers = [];
			for (let i = 0; i < pairs.length; i++) {
				const currentPairData = pairs[i];

				let baseMultiplier = 4;
				if (currentPairData?.baseAssetMultiplier?.toString().includes('.')) {
					baseMultiplier = currentPairData?.baseAssetMultiplier?.toString().split('.')[1].length;
				} else {
					baseMultiplier = 0;
				}

				let quoteMultiplier = 4;
				if (currentPairData?.quoteAssetMultiplier?.toString().includes('.')) {
					quoteMultiplier = currentPairData?.quoteAssetMultiplier?.toString().split('.')[1].length;
				} else {
					quoteMultiplier = 0;
				}
				Multipliers.push({
					base: pairs[i].baseAsset,
					quote: pairs[i].quoteAsset,
					symbol: pairs[i].symbol,
					baseMultiplier,
					quoteMultiplier,
				});
			}
			state.pairsMultiplier = Multipliers;
		},
		changePairsMultipliersV2: (state, action) => {
			let pairs = action.payload;
			let Multipliers = [];
			for (let i = 0; i < pairs.length; i++) {
				const currentPairData = pairs[i];

				let baseMultiplier = 4;
				if (currentPairData?.qtyStep?.toString().includes('.')) {
					baseMultiplier = currentPairData?.qtyStep?.toString().split('.')[1].length;
				} else {
					baseMultiplier = 0;
				}

				let quoteMultiplier = 4;
				if (currentPairData?.tickSize?.toString().includes('.')) {
					quoteMultiplier = currentPairData?.tickSize?.toString().split('.')[1].length;
				} else {
					quoteMultiplier = 0;
				}
				Multipliers.push({
					base: pairs[i].baseCoin,
					quote: pairs[i].quoteCoin,
					symbol: pairs[i].symbol,
					tickSize: currentPairData?.tickSize,
					baseMultiplier,
					quoteMultiplier,
				});
			}
			state.pairsMultiplier = Multipliers;
		},
		changeRatioData: (state, action) => {
			if (state.ratioData.marginRatio !== action.payload.marginRatio) {
				state.ratioData.marginRatio = action.payload.marginRatio;
			}
			if (state.ratioData.maintenanceMargin !== action.payload.maintenanceMargin) {
				state.ratioData.maintenanceMargin = action.payload.maintenanceMargin;
			}
			if (state.ratioData.marginBalance !== action.payload.marginBalance) {
				state.ratioData.marginBalance = action.payload.marginBalance;
			}
		},
		changeOrderBookDepthAmount: (state, action) => {
			if (action.payload.side === 'BUY') {
				state.orderBook.buyDepthAmount = action.payload.data;
			} else {
				state.orderBook.sellDepthAmount = action.payload.data;
			}
		},
	},
});

export const {
	changePairListStatus,
	AddToFavorties,
	AddToFavortiesPatchRequest,
	RemoveFromFavorties,
	changeCalcStatus,
	ChangeSpotBalance,
	ChangeFuturesBalance,
	AddFuturesBalance,
	changeOpenOrder,
	changePairs,
	ChangeMarkData,
	changeOpenPositions,
	ChangeMarginOfPosition,
	ChangeMultiplier,
	AddSpotBalance,
	changePairSetting,
	changePairsSetting,
	changeLeverageBrackets,
	changePairsMultipliers,
	changePairsMultipliersV2,
	changeReferralsStatus,
	changeSelectedPriceClick,
	changeCreatePriceAlertModalStatus,
	changePriceAlertModalStatus,
	changeRatioData,
	changeOrderBookDepthAmount,
} = FuturesStates.actions;
export default FuturesStates.reducer;
