/* eslint-disable import/no-anonymous-default-export */
import about_us from './about_us.json';
import activity_log from './activity_log.json';
import affiliate from './affiliate.json';
import airdrop from './airdrop.json';
import affiliate_dashboard from './affiliate_dashboard.json';
import api_key from './api_key.json';
import balances from './balances.json';
import buy_crypto_binance from './buy_crypto_binance.json';
import buy_crypto_mercuryo from './buy_crypto_mercuryo.json';
import contact_us from './contact_us.json';
import dashboard from './dashboard.json';
import deposit_history from './deposit_history.json';
import deposit from './deposit.json';
import download from './download.json';
import earn from './earn.json';
import fee_rates from './fee_rates.json';
import fees from './fees.json';
import fiat_history from './fiat_history.json';
import funding_rate_history from './funding_rate_history.json';
import futures_history from './futures_history.json';
import futures_overview from './futures_overview.json';
import general from './general.json';
import home from './home.json';
import insurance from './insurance.json';
import kyc from './kyc.json';
import market from './market.json';
import marviz_landing from './marviz_landing.json';
import monthly_bonus from './monthly_bonus.json';
import new_saving_history from './new_saving_history.json';
import new_saving from './new_saving.json';
import prtnership_application from './partnership_application.json';
import partnership from './partnership.json';
import policy from './policy.json';
import profile from './profile.json';
import quests from './quests.json';
import referees from './referees.json';
import referral_program from './referral_program.json';
import reward_hub from './reward_hub.json';
import saving_history from './saving_history.json';
import savings from './saving.json';
import spot_history from './spot_history.json';
import support_ticket from './support_ticket.json';
import task_center_reward from './task_center_rewards.json';
import task_center from './task_center.json';
import terms from './terms.json';
import testflight_landing from './testflight_landing.json';
import tournament from './tournament.json';
import trading_bot from './trading_bot.json';
import trading_rules from './trading_rules.json';
import transfer_history from './transfer_history.json';
import transfer_modal from './transfer_modal.json';
import vip_portal from './vip_portal.json';
import withdraw_history from './withdraw_history.json';
import withdraw from './withdraw.json';
import security from './security.json';
import commas from './commas.json';
import top_trader from './top_trader.json';

export default {
	trading_bot,
	policy,
	home,
	terms,
	savings,
	market,
	about_us,
	quests,
	support_ticket,
	fees,
	earn,
	profile,
	contact_us,
	trading_rules,
	deposit_history,
	fiat_history,
	transfer_history,
	withdraw_history,
	spot_history,
	saving_history,
	buy_crypto_binance,
	buy_crypto_mercuryo,
	activity_log,
	partnership,
	prtnership_application,
	affiliate,
	download,
	deposit,
	withdraw,
	transfer_modal,
	referral_program,
	kyc,
	airdrop,
	api_key,
	tournament,
	dashboard,
	monthly_bonus,
	balances,
	futures_history,
	futures_overview,
	marviz_landing,
	testflight_landing,
	reward_hub,
	vip_portal,
	fee_rates,
	referees,
	general,
	task_center_reward,
	task_center,
	affiliate_dashboard,
	new_saving,
	new_saving_history,
	insurance,
	funding_rate_history,
	security,
	commas,
	top_trader,
};
