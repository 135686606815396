import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Button, Modal } from '@coinlocally/cly-core';

// Assets
import { ModalMainContainer } from './profile-lang-modal.style';

// API
import { POSTEmailLang } from 'api/coinlocally/v1/users';
import { changeProfileLanguage } from 'state-manager/reducer/profile';
import toast from 'react-hot-toast';

const ProfileLangModal: FC<{ status: boolean }> = ({ status }) => {
	const dispatch = useDispatch();
	const [lang, setLang] = useState<'FA' | 'DE' | 'HY' | 'ES' | 'RU' | 'AR' | 'EN' | null>(null);
	const [btnLoading, setBtnLoading] = useState<boolean>(false);

	const onConfirmHandler = () => {
		if (lang) {
			setBtnLoading(true);
			POSTEmailLang({ language: lang }).then(() => {
				dispatch(changeProfileLanguage(lang));
			});
		} else {
			toast.error('Please chose language first!');
		}
	};

	return (
		<Modal maxWidth={468} onClose={() => {}} status={status}>
			<ModalMainContainer>
				<h3>Choose Language</h3>
				<div className='modal_body'>
					<h4>Choose your communication channel</h4>
					<p>Please choose your preferred language below to receive emails in your desired language. </p>
					<p>
						<b>Note: </b>
						{` You can change the language after logging in at any time by following these steps:"`}
					</p>
					<ol>
						<li>1. Click on "Asset" located on the right side of the header.</li>
						<li>2. Choose the dashboard option on the top right side of the page.</li>
						<li>3. Click on the "Communication Channel" to access the language settings.</li>
					</ol>
					<div className='lang_field'>
						<div className={`${lang === 'EN' ? 'active' : ''} card_row`} onClick={() => setLang('EN')}>
							<p>Click Here to receive Coinlocally Emails in English Language.</p>
						</div>
						<div className={`${lang === 'RU' ? 'active' : ''} card_row`} onClick={() => setLang('RU')}>
							<p>Для получения электронных писем от Coinlocally на русском языке, нажмите здесь.</p>
						</div>
						<div className={`${lang === 'ES' ? 'active' : ''} card_row`} onClick={() => setLang('ES')}>
							<p>Para recibir correos electrónicos de Coinlocally en español, haz clic aquí.</p>
						</div>
						<div className={`${lang === 'FA' ? 'active' : ''} card_row`} onClick={() => setLang('FA')}>
							<p>برای دریافت ایمیل های کوین لوکالی به زبان فارسی، اینجا کلیک کنید</p>
						</div>
						<div className={`${lang === 'DE' ? 'active' : ''} card_row`} onClick={() => setLang('DE')}>
							<p>Um E-Mails von Coinlocally auf Deutsch zu erhalten, klicken Sie hier.</p>
						</div>
						<div className={`${lang === 'HY' ? 'active' : ''} card_row`} onClick={() => setLang('HY')}>
							<p>Եթե ուզում եք ստանալ CoinLocally-ի էլ. փոստի նամակները հայերենով, սեղմեք այստեղ .</p>
						</div>
						<div className={`${lang === 'AR' ? 'active' : ''} card_row`} onClick={() => setLang('AR')}>
							<p style={{ direction: 'rtl' }}>للحصول على رسائل البريد الإلكتروني من Coinlocally باللغة العربية، انقر هنا.</p>
						</div>
					</div>
				</div>
				<Button size='medium' variant='primary' onClick={onConfirmHandler} isLoading={btnLoading}>
					Confirm
				</Button>
			</ModalMainContainer>
		</Modal>
	);
};

export default ProfileLangModal;
