import { createSlice } from '@reduxjs/toolkit';

export const spotSlice = createSlice({
	name: 'spot',
	initialState: {
		pairs: [],
		multiplier: {
			base: 4,
			quote: 4,
		},
		openOrders: null,
		balances: [],
		selectedPriceClick: 0,
	},
	reducers: {
		initPairs: (state, action) => {
			state.pairs = action.payload;
		},
		updatePrices: (state, action) => {
			for (const item of action.payload) {
				let index = state.pairs.findIndex(p => p.symbol === item.symbol);
				if (index !== -1) {
					state.pairs[index].lastPrice = item.lastPrice;
					state.pairs[index].prevPrice24h = item.prevPrice24h;
					state.pairs[index].price24hPercentage = item.price24hPcnt;
					state.pairs[index].highPrice24h = item.highPrice24h;
					state.pairs[index].lowPrice24h = item.lowPrice24h;
					state.pairs[index].volume24h = item.volume24h;
				}
			}
		},
		changeMultiplier: (state, action) => {
			state.multiplier = action.payload;
		},
		changeBalances: (state, action) => {
			if (action.payload.type === 'init') {
				const balances = action.payload.data.map(item => {
					return {
						coin: item.asset,
						free: item.freeBalance,
						locked: item.lockedBalance,
						walletBalance: item.balance,
					};
				});
				state.balances = balances;
			} else {
				const data = action.payload.data;
				const index = state.balances.findIndex(item => item.coin === data.coin);
				if (index === -1) {
					state.balances.push(data);
				} else {
					state.balances[index].coin = data.coin;
					state.balances[index].free = data.free;
					state.balances[index].locked = data.locked;
					state.balances[index].walletBalance = data.walletBalance;
				}
			}
		},
		changeOpenOrder: (state, action) => {
			if (action.payload.type === 'init') {
				state.openOrders = action.payload.data;
			} else {
				const update = action.payload.data;
				if (action.payload.type === 'REMOVED') {
					state.openOrders = state.openOrders.filter(item => item.orderId !== update.orderId);
				} else if (action.payload.type === 'NEW') {
					state.openOrders.unshift(update);
				}
			}
		},
		changeSelectedPriceClick: (state, action) => {
			state.selectedPriceClick = action.payload;
		},
	},
});

export const { initPairs, updatePrices, changeMultiplier, changeOpenOrder, changeBalances, changeSelectedPriceClick } = spotSlice.actions;
export default spotSlice.reducer;
