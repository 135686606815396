/* eslint-disable import/no-anonymous-default-export */
export default {
	light: {
		background: {
			default: 'white',
			profile: '#F1F1F4',
			profileAside: 'white',
			profileAsideActive: '#F1F1F4',
		},
		textColor: {
			default: '#1E2329',
			accent: 'rgb(112, 122, 138)',
		},
		border: {
			default: '0.6px solid rgb(238, 240, 242)',
			bold: '2px solid rgb(238, 240, 242)',
		},
		assetsBg: '#F2F3F5',
		fieldBg: 'white',
		blackAndWhite: '#000000',
		hover: '#F5F5F5',
		hideBalancesColor: 'rgb(112, 122, 138)',
		profileActiveButton: '#F2F2F7',
		WidthrawBtnColor: '#2A2A2A',
		red: '#FF3B30',
		green: '#2EBD85',
		yellow: '#FCD535',
	},
	dark: {
		background: {
			default: '#1E2328',
			profile: '#272d33',
			profileAside: '#1E2328',
			profileAsideActive: '#374048',
		},
		textColor: {
			default: '#EAECEF',
			accent: 'rgb(132, 142, 156)',
		},
		border: {
			default: '0.6px solid rgb(57 63 71)',
			bold: '2px solid rgb(57 63 71)',
		},
		assetsBg: '#2B3139',
		fieldBg: '#1E2026',
		blackAndWhite: 'white',
		hover: '#2d353a',
		hideBalancesColor: 'rgb(162 171 185)',
		profileActiveButton: '#424b54',
		WidthrawBtnColor: '#424b54',
		red: '#FF7070',
		green: '#54D487',
		yellow: '#FCD535',
	},
};
