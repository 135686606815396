import styled from '@emotion/styled';

export const MainField = styled.div(props => ({
	width: '100%',
	padding: '40px',
	borderRadius: '4px',
	backgroundColor: props.theme.palette.background.profileAside,

	'& .save_btn': {
		backgroundColor: '#FCD535 !important',
		color: 'black',
		boxShadow: 'none !important',
		textTransform: 'capitalize',
		fontWeight: 'bold',
		marginTop: '20px',
	},
	'@media(max-width: 600px)': {
		padding: '24px',
	},
}));

export const HeaderFiled = styled.div(props => ({
	display: 'flex',
	alignItems: 'center',
	gap: '20px',

	'@media(max-width: 600px)': {
		flexDirection: 'column',
	},

	'& .information': {
		display: 'flex',
		flexDirection: 'column',
		'@media(max-width: 600px)': {
			flexDirection: 'column-reverse',
		},
	},

	'& .avatar_field': {
		minWidth: '80px',
		minHeight: '80px',
		backgroundColor: props.theme.mode === 'light' ? '#F2F3F6' : '#323B44',
		position: 'relative',
		borderRadius: '60px',
		backgroundImage: `url(${props.avatar})`,
		backgroundSize: props.avatarSize ? 'cover' : '80%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',

		i: {
			position: 'absolute',
			right: '-10px',
			bottom: '-10px',
			backgroundColor: props.theme.mode === 'light' ? '#fff' : '#1E2328',
			padding: '8px',
			border: props.theme.mode === 'light' ? '1px solid #CCD0DA' : '1px solid #373e44',
			borderRadius: '50px',
			color: props.theme.mode === 'light' ? '#4F4F4F' : '#9d9d9d',
			fontSize: '0.9rem',
			cursor: 'pointer',
			width: '30px',
			height: '30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},

	'& .name': {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
		color: props.theme.palette.blackAndWhite,
		fontSize: '0.9rem',

		b: {
			fontSize: '1.2rem',
			'@media(max-width:600px)': {
				marginBottom: '-0.5rem',
			},
		},

		span: {
			width: '2px',
			height: '30px',
			backgroundColor: props.theme.mode === 'light' ? '#E6EAEE' : '#373E44',
			'@media(max-width: 600px)': {
				display: 'none',
			},
		},
		'@media(max-width: 600px)': {
			flexDirection: 'column',
		},
	},

	small: {
		color: props.theme.mode === 'light' ? '#8190A2' : '#909090',
		'@media(max-width: 600px)': {
			textAlign: 'center',
			marginBottom: '1rem',
		},
	},
}));

export const ActionField = styled.div(props => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: '30px',
	marginTop: '30px',
	paddingBottom: '20px',
	marginBottom: '20px',
	borderBottom: props.theme.mode === 'light' ? '1px solid #E6EAEE' : '1px solid #3c444d',

	'& .form_group': {
		width: '48.7%',
		display: 'flex',
		flexDirection: 'column',
		gap: '5px',

		'@media (max-width: 800px)': {
			width: '100%',
		},

		input: {
			width: '100%',
			border: 'none',
			borderRadius: '4px',
			backgroundColor: props.theme.mode === 'light' ? '#F2F3F6' : '#323B44',
			height: '45px',
			padding: '15px',
			color: props.theme.palette.blackAndWhite,
		},

		label: {
			color: props.theme.palette.blackAndWhite,
		},

		textarea: {
			width: '100%',
			border: 'none',
			borderRadius: '4px',
			backgroundColor: props.theme.mode === 'light' ? '#F2F3F6' : '#323B44',
			height: '150px',
			padding: '15px',
			color: props.theme.palette.blackAndWhite,
		},
	},

	'& .w_100': {
		width: '100%',
	},

	'& .MuiCheckbox-root': {
		color: '#F3C51A !important',
	},

	'& .MuiTypography-root': {
		color: props.theme.palette.blackAndWhite,
	},

	'& .MuiOutlinedInput-input': {
		padding: '0 10px !important',
	},

	fieldset: {
		border: 'none',
	},

	'& .MuiOutlinedInput-root': {
		backgroundColor: props.theme.mode === 'light' ? '#F2F3F6' : '#323B44',
	},
}));

export const SocialField = styled.div(props => ({
	h3: {
		color: props.theme.palette.blackAndWhite,
		fontSize: '1.5rem',
	},

	'& .form_field': {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '30px',

		'@media (max-width: 800px)': {
			flexDirection: 'column',
		},
	},

	'& .form_group': {
		width: '45%',
		flexGrow: '1',
		display: 'flex',
		flexDirection: 'column',
		gap: '5px',
		position: 'relative',

		'@media (max-width: 800px)': {
			width: '100%',
		},

		input: {
			width: '100%',
			border: 'none',
			borderRadius: '4px',
			backgroundColor: props.theme.mode === 'light' ? '#F2F3F6' : '#323B44',
			height: '45px',
			padding: '15px',
			paddingLeft: '53px',
			color: props.theme.palette.blackAndWhite,
		},

		label: {
			color: props.theme.palette.blackAndWhite,
		},

		img: {
			width: '30px',
			position: 'absolute',
			left: '10px',
			top: '36px',
		},
	},
}));

export const ModalBody = styled.div(props => ({
	padding: '30px',

	'& .button_group': {
		width: '80%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0 auto',
		marginTop: '40px',

		button: {
			width: '100%',
			textTransform: 'unset',
			padding: '5px 20px',
			borderRadius: '50px',
			boxShadow: 'none',

			'&:last-child': {
				backgroundColor: props.theme.palette.mainColors.accent,
				color: 'black',
				marginLeft: '10px',

				'&:hover': {
					backgroundColor: props.theme.palette.mainColors.accent_hover,
				},
			},
			'&:first-of-type': {
				backgroundColor: '#ff2f63',
				color: 'white',

				'&:hover': {
					backgroundColor: '#ff5978',
				},
			},
		},
	},
}));
