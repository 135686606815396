import { useAppSelector } from 'hooks/redux-helper';
import { useSelector } from 'react-redux';

function DashboardAsideConfig() {
	const ticketCount = useSelector((state: any) => state.Profile.haveTicket);
	const referralType = useAppSelector(state => state.Profile.referralType);
	const Items = [
		{
			title: 'Dashboard',
			icon: 'fas fa-th-large',
			link: '/dashboard/profile',
		},
		{
			title: 'Overview',
			icon: 'fal fa-chart-pie',
			submenu: [
				{
					title: 'Futures',
					link: '/overview/futures',
				},
			],
		},
		{
			title: 'Forex',
			icon: 'fal fa-analytics',
			link: '/dashboard/forex',
		},

		{
			title: 'Security',
			icon: 'fal fa-fingerprint',
			link: '/dashboard/security',
		},
		{
			title: 'KYC',
			icon: 'fal fa-address-card',
			link: '/KYC',
		},
		{
			title: 'Referral',
			icon: 'fal fa-qrcode',
			link: '/referral',
		},
		{
			...((referralType === 'ANGEL' || referralType === 'ANGEL2' || referralType === 'ANGEL3') && {
				title: 'Affiliate',
				icon: 'far fa-hands-helping',
				link: '/dashboard/affiliate',
			}),
		},
		{
			title: 'Support Ticket',
			icon: 'fal fa-headset',
			link: '/supportTicket',
			badge: ticketCount,
		},
		{
			title: 'Earn',
			icon: 'fal fa-piggy-bank',
			link: '/account/saving',
		},
		{
			title: 'Api Key',
			icon: 'fal fa-key',
			link: '/dashboard/api-key',
		},
		{
			title: 'Rewards',
			icon: 'fal fa-swords',
			link: '/rewards',
		},
		{
			title: 'Fee Rates',
			icon: 'fal fa-badge-percent',
			link: '/fee-rates',
		},
		{
			title: 'History',
			icon: 'fal fa-history',
			submenu: [
				{
					title: 'Deposit History',
					link: '/history/deposit',
				},
				{
					title: 'Withdraw History',
					link: '/history/withdraw',
				},
				{
					title: 'Fiat History',
					link: '/history/fiat',
				},
				{
					title: 'Savings History',
					link: '/history/savings',
				},
				{
					title: 'Spot History',
					link: '/history/spot',
				},
				{
					title: 'futures History',
					link: '/history/futures',
				},
				{
					title: 'Transfer History',
					link: '/history/transfer',
				},
			],
		},
		// TODO: remove relevant components of commented routes
		// {
		// 	title: 'Partnership',
		// 	icon: 'fal fa-handshake-alt',
		// 	submenu: [
		// 		{
		// 			title: 'Information',
		// 			link: '/partner/information',
		// 		},
		// 		{
		// 			title: 'Credentials',
		// 			link: '/partner/credentials',
		// 		},
		// 		{
		// 			title: 'Clients',
		// 			link: '/partner/clients',
		// 		},
		// 		{
		// 			title: 'Transfers',
		// 			link: '/partner/transfers',
		// 		},
		// 		{
		// 			title: 'Deposits',
		// 			link: '/partner/deposits',
		// 		},
		// 	],
		// },
	];

	return Items;
}

export default DashboardAsideConfig;
