import RequestHandler from '../../../customize/requestHandler';

import { GetInfo, getSecurityStrategies } from '../../../state-manager/reducer/profile';

export const getUserInfo = async dispatch => {
	return RequestHandler.call({ url: 'api/v1/users/profile', method: 'get' }).then(res => dispatch(GetInfo(res.data.result)));
};

export const getSecurityStrategiesApi = async dispatch => {
	return RequestHandler.call({ url: 'api/v1/security/strategies', method: 'get' }).then(res =>
		dispatch(getSecurityStrategies(res.data.result)),
	);
};
