import themePalette from './theme.palettes';
import ThemeBanner from './theme.home-page-banner';

export const getDesignTokens = (mode: 'dark' | 'light') => ({
	mode,
	palette: {
		mainColors: {
			dark_accent: '#d7ad02',
			accent: '#FCD535',
			accent_hover: '#FFE16B',
			accent_gradiant: 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
			accent_gradiant_hover: 'linear-gradient(180deg, #fae48a 0%, #ffcd30 100%)',
			red: '#FF7070',
			green: '#2EBD85',
		},
		mode,
		...themePalette[mode],
	},
	pxToRem: (px: number) => px / 16 + 'rem',
	size: {
		radius: {
			sharp: '4px',
			halfRounded: '8px',
			rounded: '600px',
		},
		text: {
			ts10: '0.625rem',
			ts12: '0.75rem',
			ts14: '0.875rem',
			ts16: '1rem',
			ts18: '1.125rem',
			ts20: '1.25rem',
			ts22: '1.375rem',
			ts24: '1.5rem',
			ts26: '1.625rem',
			ts28: '1.75rem',
			ts30: '1.875rem',
			ts32: '2rem',
			ts40: '2.5rem',
			ts48: '3rem',
			ts56: '3.5rem',
			ts88: '5.5rem',
		},
	},
	...(mode === 'light'
		? {
				colors: {
					background: {
						primary: '#FFFFFF',
						secondary: '#F7F7F7',
						tertiary: '#F7F7F7',
						quaternary: '#FFFFFF',
						green: '#D7F5E3',
						red: '#FFE3E3',
						yellow: '#FCD535',
					},
					text: {
						primary: '#000000',
						secondary: '#6D6D70',
						tertiary: '#BEBEC0',
						quaternary: '#D4D4D5',
						green: '#009B79',
						red: '#E51717',
						yellow: '#FCD535',
					},
					border: {
						primary: '#E1E3E5',
					},
				},
		  }
		: {
				colors: {
					background: {
						primary: '#000000',
						secondary: '#18181A',
						tertiary: '#1C1C1E',
						quaternary: '#2C2C2E',
						green: '#1F3C35',
						red: '#4C2020',
						yellow: '#FCD535',
					},
					text: {
						primary: '#FFFFFF',
						secondary: '#939393',
						tertiary: '#464649',
						quaternary: '#2A2A2C',
						green: '#06D6A9',
						red: '#FF6961',
						yellow: '#FCD535',
					},
					border: {
						primary: '#38383A',
					},
				},
		  }),
	...ThemeBanner,
});
