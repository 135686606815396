import requestHandler from 'utils/requestHandler';
import RequestHandler from 'customize/requestHandler';

interface IBuyCryptoMercuryoAssets {
	error: boolean;
	code: number;
	result: {
		fiat: string[];
		crypto: string[];
		config: {
			base: {
				[key: string]: string;
			};
			has_withdrawal_fee: {
				[key: string]: string;
			};
			display_options: {
				[key: string]: {
					fullname: string;
					total_digits: number;
					display_digits: number;
				};
			};
			icons: {
				[key: string]: {
					svg: string;
					relative: {
						svg: string;
						png: string;
					};
					png: string;
				};
			};
			networks: {
				[key: string]: {
					name: string;
					icons: {
						svg: string;
						relative: {
							svg: string;
							png: string;
						};
						png: string;
					};
				};
			};
			crypto_currencies: {
				currency: string;
				network: string;
				show_network_icon: boolean;
				network_label: string;
				contract: string;
				network_ud?: string;
			}[];
			default_networks: {
				[key: string]: string;
			};
		};
	};
}

export const getAssets = async () => {
	return requestHandler.call<IBuyCryptoMercuryoAssets>({ url: `api/v1/payments/mercuryo/assets`, method: 'get' }).then(res => res.result);
};

export const getBinanceAssets = async () => {
	return RequestHandler.call({ url: `api/v1/payments/binance/assets`, method: 'get' }).then(res => res.data.result);
};

export const GetBinanceRate = async () => {
	return RequestHandler.call({ url: `api/v1/payments/binance/rates`, method: 'get' }).then(res => res.data.result);
};

export const getAssetsRates = async (cryptoAsset: string, fiatAsset: string, type: string, side: string, amount: string) => {
	return RequestHandler.call({
		url: `api/v1/payments/mercuryo/rates?cryptoAsset=${cryptoAsset}&fiatAsset=${fiatAsset}&type=${type}&side=${side}&amount=${amount}`,
		method: 'get',
	}).then(res => res.data.result);
};

export const paymentGetWay = async (
	channel: string,
	cryptoAsset: string,
	cryptoNetwork: string,
	fiatAsset: string,
	type: string,
	amount: string,
) => {
	return RequestHandler.call({
		url: `api/v1/payments/gateway?channel=${channel}&cryptoAsset=${cryptoAsset}&cryptoNetwork=${cryptoNetwork}&fiatAsset=${fiatAsset}&type=${type}&amount=${amount}`,
		method: 'get',
	}).then(res => res.data.result);
};
