function maskEmail(email) {
	let skipFirstChars = 3;
	let firstThreeChar = email.slice(0, skipFirstChars);

	let domainIndexStart = email.lastIndexOf('@');
	let maskedEmail = email.slice(skipFirstChars, domainIndexStart - 2);
	maskedEmail = '****';
	let domainPlusPreviousChar = email.slice(domainIndexStart - 2, email.length);

	return firstThreeChar.concat(maskedEmail).concat(domainPlusPreviousChar);
}

export default maskEmail;
