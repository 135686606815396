import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enJSON from './locales/en';

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		supportedLngs: ['en'],
		resources: {
			en: {
				...enJSON,
			},
		},
	});

export default i18n;
