import Layout from 'component/layout/layout';
import { FC, ReactNode } from 'react';
import Aside from './aside';
import { ContentField, MainField } from './style';
import { Outlet } from 'react-router-dom';

const DashboardLayout: FC<{ children: ReactNode; footerDisplay: boolean; padding?: number }> = ({ children, footerDisplay, padding }) => {
	return (
		<Layout footerDisplay={footerDisplay} forceLight={false}>
			<MainField>
				<div>
					<Aside />
				</div>
				<ContentField style={{ ...(padding !== undefined ? { padding: padding } : {}) }}>
					{children}
					<Outlet />
				</ContentField>
			</MainField>
		</Layout>
	);
};

export default DashboardLayout;
