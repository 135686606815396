import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeMobileDownloadStatus } from '../../state-manager/reducer/profile';

// ui
import Button from 'cly-uikit/elements/button';
import { H3 } from 'cly-uikit/elements/text/headings';
import P from 'cly-uikit/elements/text/P';

// assets
import { AppModalContainer } from '../../pages/home/components/styles/winter/app-modal';
import MobilePopUp from '../../component/layout/mobile-popup';
import logo_light from '../../assets/img/home/app-modal/logo_light.svg';
import logo_dark from '../../assets/img/home/app-modal/logo_dark.svg';

// hooks
import useWindowDimensions from '../../hooks/get-window-dimensions';
import { useState } from 'react';
import { useAppSelector } from 'hooks/redux-helper';

function AppModal() {
	const { width } = useWindowDimensions();
	const dispatch = useDispatch();
	const modalMobileDownloadSkipped = useSelector(state => state.Profile.modalMobileDownloadSkipped);
	const isMobile = useAppSelector(state => state.Home.isMobile);
	const [isActive, setActive] = useState(true);

	const theme = useAppSelector(state => state.ThemeProvider.theme);

	const DismissModal = () => {
		setActive(false);
		setTimeout(() => {
			dispatch(changeMobileDownloadStatus({ type: 'modal' }));
		}, 400);
	};

	function getMobileOperatingSystem() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone';
		}
		if (/android/i.test(userAgent)) {
			return 'Android';
		}
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS';
		}
		return 'unknown';
	}

	const OpenAppClickHandler = () => {
		if (getMobileOperatingSystem() === 'Android') {
			window.location.href = 'coinlocally://open';
			setTimeout(function () {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.coinlocally.android';
			}, 1000);
		} else if (getMobileOperatingSystem() === 'iOS') {
			window.location.href = 'Coinlocally://open';
			setTimeout(function () {
				window.location.href = 'https://apps.apple.com/us/app/coinlocally/id1495966572';
			}, 1000);
		} else {
			window.location.href = 'https://coinlocally.com/download';
		}
	};

	return (
		width < 576 &&
		!modalMobileDownloadSkipped &&
		!isMobile && (
			<MobilePopUp
				isActive={isActive}
				setActive={setActive}
				style={{ zIndex: '10000000' }}
				bodyStyle={{
					border: '1px solid #000000',
					borderRadius: '24px 24px 0px 0px',
					background: 'var(--color-background-section-1)',
				}}
			>
				<AppModalContainer>
					<img src={theme === 'light' ? logo_light : logo_dark} alt='' className='modal_logo' />
					<H3>Enjoy safer & Faster Trades</H3>
					<P>Apply the app to make money anytime, anywhere, through trading bots, Leverage, Futures, and other features</P>
					<Button variant='primary' className='modal_action_button' onClick={OpenAppClickHandler}>
						Open Coinlocally App
					</Button>
					<Button className='modal_later' variant='ghost' onClick={DismissModal}>
						Later
					</Button>
				</AppModalContainer>
			</MobilePopUp>
		)
	);
}

export default AppModal;
