import { createSlice } from '@reduxjs/toolkit';


export const spotFavoriteSlice = createSlice({
    name: 'spotFavorite',
    initialState: {
        List: JSON.parse(localStorage.getItem('spotFavorite')) || []
    },
    reducers: {
        AddToList: (state, action) => {
            var prev = JSON.parse(localStorage.getItem('spotFavorite')) || [];
            prev.push(action.payload);
            localStorage.setItem('spotFavorite', JSON.stringify(prev));
            state.List = prev;
        },
        RemoveFromList: (state, action) => {
            var prev = JSON.parse(localStorage.getItem('spotFavorite')) || [];
            prev = prev.filter(item => item !== action.payload);
            localStorage.setItem('spotFavorite', JSON.stringify(prev));
            state.List = prev;
        }
    },
});

export const { AddToList, RemoveFromList } = spotFavoriteSlice.actions;
export default spotFavoriteSlice.reducer;
