import { lazy } from 'react';

// layout
import Layout from 'component/layout/layout';
import DashboardLayout from 'shared/components/layout/dashboard-layout';

// components
const RefferalPage = lazy(() => import('route/angel/angel'));
const AngelForm = lazy(() => import('route/angel/angelForm'));
const Referral = lazy(() => import('route/referral/referral'));
const AboutUs = lazy(() => import('route/other/about-us'));
const Home = lazy(() => import('pages/home-xmas'));
const Terms = lazy(() => import('route/other/term-of-us'));
const Privacy = lazy(() => import('route/other/privacy'));
const DemoLanding = lazy(() => import('route/landing/demo'));
const Market = lazy(() => import('route/market/market'));
const Community = lazy(() => import('route/other/commiunity'));
const ICO = lazy(() => import('route/other/ICO'));
const Fees = lazy(() => import('route/other/fees'));

const TradingRules = lazy(() => import('route/other/trading-rules'));
const SurveyLanding = lazy(() => import('route/landing/survey'));
const Login = lazy(() => import('route/auth/login'));
const Register = lazy(() => import('route/auth/register'));
const ForgetPassword = lazy(() => import('route/auth/forget'));
const Announcement = lazy(() => import('route/other/announcement/announcement'));
const AnnouncementArticle = lazy(() => import('route/other/announcement/announcement-article'));
const Invoice = lazy(() => import('route/invoice/invoice'));
const AnnouncementCategory = lazy(() => import('route/other/announcement/category'));
const IntroductionTurkey = lazy(() => import('route/landing/introduction'));
const Withdraw = lazy(() => import('route/withdraw/withdraw'));
const Deposit = lazy(() => import('route/deposit/deposit'));
const MercuryoBuyCrypto = lazy(() => import('route/buy-crypto/mercuryo'));
const Partnership = lazy(() => import('route/partnership/partnership'));
const PartnershipAppliationForm = lazy(() => import('route/partnership/application-form'));
const ViewProfile = lazy(() => import('route/profile/profile/view'));
const DownloadLanding = lazy(() => import('route/download/download-landing'));
const APIKEY = lazy(() => import('route/landing/apikey'));
const MarvizLanding = lazy(() => import('route/landing/marviz'));
const TestFlightLanding = lazy(() => import('route/landing/test-flight'));
const RewardHub = lazy(() => import('route/rewards/reward-hub'));
const TaskCenter = lazy(() => import('route/task-center/TaskCenter'));
const VipPortal = lazy(() => import('route/vip-portal/vip-portal'));
const AiMultiLanguage = lazy(() => import('route/ai-multi-language/ai-multilanguage'));
const Tournament = lazy(() => import('pages/tournament'));
const TopTraders = lazy(() => import('pages/top-traders'));
const ForexTerminal = lazy(() => import('pages/forex-terminal'));

const TaskCenterRewards = lazy(() => import('pages/task-center-rewards'));
const Saving = lazy(() => import('pages/saving'));
const insurance = lazy(() => import('pages/insurance'));
const FundingRateHistory = lazy(() => import('pages/funding-rate-history'));

//landings
const CommasLanding = lazy(() => import('pages/landing/3commas'));
const XmasLanding = lazy(() => import('pages/landing/xmas-landing'));
const BlackFriday = lazy(() => import('pages/landing/black-friday'));

// dashboard
const DashboardProfile = lazy(() => import('pages/dashboard/profile'));
const DashboardSecurity = lazy(() => import('pages/dashboard/security'));
const APIKey = lazy(() => import('pages/dashboard/api-key'));
const ManageApiKey = lazy(() => import('pages/dashboard/api-key/manage'));
const DashboardSecurityGoogleAuth = lazy(() => import('pages/dashboard/security/google-auth'));
const DashboardSecurityAntiPhishing = lazy(() => import('pages/dashboard/security/anti-phishing'));
const DashboardSecurityDeleteAccount = lazy(() => import('pages/dashboard/security/delete-account'));
const DashboardSecurityFreezeAccount = lazy(() => import('pages/dashboard/security/freeze-account'));
const DashboardSecurityAccountActivities = lazy(() => import('pages/dashboard/security/account-activities'));
const DashboardAPIkeyDoc = lazy(() => import('pages/dashboard/api-key/document'));
const AffiliateDashboard = lazy(() => import('pages/dashboard/affiliate'));

const ForexAccount = lazy(() => import('pages/dashboard/forex'));
const ForexHistory = lazy(() => import('pages/dashboard/forex/history'));

const TradingBot = lazy(() => import('pages/trading-bot'));
// TODO: this will be used when backend become ready
// const DashboardSecurityDeviceManagement = lazy(() => import('pages/dashboard/security/device-management'));

const MarginTradeData = lazy(() => import('pages/margin-trade-data'));

// SUBLayout profile
export default function RoutesJson() {
	return [
		{
			route: ':lng/',
			component: Home,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/about-us',
			component: AboutUs,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/Terms',
			component: Terms,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/policy',
			component: Privacy,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/landing/demo',
			component: DemoLanding,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/landing/apikey',
			component: APIKEY,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/market',
			component: Market,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/landing/survey',
			component: SurveyLanding,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/community',
			component: Community,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/CLYC',
			component: ICO,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/fees',
			component: Fees,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: ':lng/rules',
			component: TradingRules,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/affiliate',
			component: RefferalPage,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/affiliate-form',
			component: AngelForm,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/referral-program',
			component: Referral,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/invoice/:id',
			component: Invoice,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/announcement-article/:articleId',
			component: AnnouncementArticle,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/announcement',
			component: Announcement,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},

		{
			route: ':lng/announcement/category',
			component: AnnouncementCategory,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/introduction',
			component: IntroductionTurkey,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/Partnership',
			component: Partnership,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/leaderboard',
			component: TopTraders,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/profile/:username',
			component: ViewProfile,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/Partnership/application',
			component: PartnershipAppliationForm,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/withdraw',
			component: Withdraw,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/deposit',
			component: Deposit,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/login',
			component: Login,
			authType: 'no-auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/register',
			component: Register,
			authType: 'no-auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/forget-password',
			component: ForgetPassword,
			authType: 'no-auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/buy-crypto/mercuryo',
			component: MercuryoBuyCrypto,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/download',
			component: DownloadLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/landing/marviz',
			component: MarvizLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/landing/3commas',
			component: CommasLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/landing/testflight',
			component: TestFlightLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/reward-hub',
			component: RewardHub,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/task-center',
			component: TaskCenter,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/vip-portal',
			component: VipPortal,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/landing/ai-multilanguage',
			component: AiMultiLanguage,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/landing/xmas-cashback',
			component: XmasLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/black-friday',
			component: BlackFriday,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/tournament',
			component: Tournament,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/task-center/rewards',
			component: TaskCenterRewards,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/saving',
			component: Saving,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/funding-rate-history',
			component: FundingRateHistory,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/dashboard/affiliate',
			component: AffiliateDashboard,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, padding: 0 } },
			sub: null,
		},
		{
			route: ':lng/dashboard/forex',
			component: ForexAccount,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/forex/history/:id',
			component: ForexHistory,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/insurance',
			component: insurance,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/dashboard/profile',
			component: DashboardProfile,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/security',
			component: DashboardSecurity,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/api-key',
			component: APIKey,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/api-key/docs',
			component: DashboardAPIkeyDoc,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/dashboard/api-key/manage',
			component: ManageApiKey,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/security/google-auth',
			component: DashboardSecurityGoogleAuth,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/security/anti-phishing',
			component: DashboardSecurityAntiPhishing,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/security/delete-account',
			component: DashboardSecurityDeleteAccount,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/dashboard/security/freeze-account',
			component: DashboardSecurityFreezeAccount,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		//TODO: disables Temporary until backend be ready
		// {
		// 	route: ':lng/dashboard/security/device-management',
		// 	component: DashboardSecurityDeviceManagement,
		// 	authType: 'auth',
		// 	layout: { type: 'normal', props: { footerDisplay: false } },
		// 	sub: null,
		// },
		{
			route: ':lng/dashboard/security/account-activities',
			component: DashboardSecurityAccountActivities,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/trading-bot',
			component: TradingBot,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/margin-trade-data',
			component: MarginTradeData,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: ':lng/forex-terminal/:loginId',
			component: ForexTerminal,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: ':lng/forex-terminal',
			component: ForexTerminal,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
	];
}

export function RoutesLayoutHandler(props) {
	let layout = props.layout;

	if (layout === null) {
		return props.children;
	} else {
		return layout.type === 'normal' ? (
			<Layout {...layout.props}>{props.children}</Layout>
		) : layout.type === 'dashboard' ? (
			<DashboardLayout {...layout.props}>{props.children}</DashboardLayout>
		) : (
			''
		);
	}
}
